import type { ThemeColors, ThemeFont } from '../../config/program.types';
import { getCurrentProgramConfig } from '../../config/programHelper';

interface DefaultTheme {
  font: ThemeFont;
  colors: ThemeColors;
}

const DEFAULT_THEME: DefaultTheme = {
  font: {
    fontSans: 'Factor-A',
    fontDisplay: 'Factor-A',
    fontArch: 'Archivo',
  },
  colors: {
    foreground: '203 98% 33%',
    background: '0 100% 100%',
    accent: '205 100% 38%',
    secondary: '0 0% 0%',
    success: '142 76% 36%',
    warning: '36 100% 50%',
    danger: '360 76% 50%',
    disabled: '210 10% 45%',
    buttonPrimary: 'var(--accent)',
    buttonPrimaryText: '255 100% 100%',
    buttonSecondary: 'var(--secondary)',
    buttonSecondaryText: 'var(--secondary)',
    headerBackground: '0 0% 100%',
  },
};

export const applyProgramTheme = () => {
  const { theme } = getCurrentProgramConfig();
  const root = document.documentElement;

  const themeConfig = theme || DEFAULT_THEME;

  root.style.setProperty('--program-font-sans', themeConfig.font?.fontSans || DEFAULT_THEME.font.fontSans);
  root.style.setProperty('--program-font-display', themeConfig.font?.fontDisplay || DEFAULT_THEME.font.fontDisplay);
  root.style.setProperty('--program-font-arch', themeConfig.font?.fontArch || DEFAULT_THEME.font.fontArch);
  root.style.setProperty('--program-foreground', themeConfig.colors?.foreground || DEFAULT_THEME.colors.foreground);
  root.style.setProperty('--program-background', themeConfig.colors?.background || DEFAULT_THEME.colors.background);
  root.style.setProperty('--program-accent', themeConfig.colors?.accent || DEFAULT_THEME.colors.accent);
  root.style.setProperty('--program-secondary', themeConfig.colors?.secondary || DEFAULT_THEME.colors.secondary);
  root.style.setProperty('--program-success', themeConfig.colors?.success || DEFAULT_THEME.colors.success);
  root.style.setProperty('--program-warning', themeConfig.colors?.warning || DEFAULT_THEME.colors.warning);
  root.style.setProperty('--program-danger', themeConfig.colors?.danger || DEFAULT_THEME.colors.danger);
  root.style.setProperty('--program-disabled', themeConfig.colors?.disabled || DEFAULT_THEME.colors.disabled);
  root.style.setProperty(
    '--program-button-primary',
    themeConfig.colors?.buttonPrimary || DEFAULT_THEME.colors.buttonPrimary,
  );
  root.style.setProperty(
    '--program-button-primary-text',
    themeConfig.colors?.buttonPrimaryText || DEFAULT_THEME.colors.buttonPrimaryText,
  );
  root.style.setProperty(
    '--program-button-secondary',
    themeConfig.colors?.buttonSecondary || DEFAULT_THEME.colors.buttonSecondary,
  );
  root.style.setProperty(
    '--program-button-secondary-text',
    themeConfig.colors?.buttonSecondaryText || DEFAULT_THEME.colors.buttonSecondaryText,
  );

  root.style.setProperty(
    '--program-header-background',
    themeConfig.colors?.headerBackground || DEFAULT_THEME.colors.headerBackground,
  );
};
