import Button from '@pesto/hubble-shared/components/UI/Button/Button';

import { Program } from '../../../../config/program';
import { useDebugger } from '../hooks/useDebugger';

export const ProgramDebugger = () => {
  const programsList = Object.values(Program);
  const { updateSelectedProgram, selectedProgram, resetProgram } = useDebugger();
  return (
    <div className="flex flex-col gap-2">
      <select value={selectedProgram || ''} onChange={e => updateSelectedProgram(e.target.value as Program)}>
        {programsList.map(program => (
          <option key={program}>{program}</option>
        ))}
      </select>
      <Button buttonType="primary" text="Reset Program" onClick={resetProgram} />
    </div>
  );
};
