// URL PARAMS
export const UTM_STRING_LS_KEY = 'utmString';
export const UTM_SOURCE_LS_KEY = 'utmSource';
export const UTM_MEDIUM_LS_KEY = 'utmMedium';
export const UTM_CAMPAIGN_LS_KEY = 'utmCampaign';
export const UTM_CONTENT_LS_KEY = 'utmContent';
export const LG_SUB_ID_LS_KEY = 'lgSubId';
export const LG_LEAD_ID_LS_KEY = 'lgLeadId';
export const TM_SUB_ID_1_KEY = 'tmSubid1';
export const TM_SUB_ID_2_KEY = 'tmSubid2';
export const TM_SUB_ID_3_KEY = 'tmSubid3';
export const MD_AFFILIATE_ID_LS_KEY = 'mdAffiliateId';
export const TRANS_ID_LS_KEY = 'transId';
export const FLEX_REF_ID_LS_KEY = 'flexRefId';
export const GCLID_LS_KEY = 'gclid';
export const FBCLID_LS_KEY = 'fbclid';
export const MSCLKID_LS_KEY = 'msclkid';
export const REF_LS_KEY = 'affiliateRef';
export const NERD_WALLET_REF_LS_KEY = 'nerdwalletRef';
export const PESTOANONID_LS_KEY = 'pestoAnonId';
export const REFERRAL_CODE_LS_KEY = 'referralCode';

// APPLICATION KEYS
export const SECURITY_TYPE_PARAM = 'ctype';
export const PAYMENT_AMOUNT_LS_KEY = 'paymentAmount';
export const IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY = 'isIgnoreSegmentAndPestoTrackingForDevMode';

// ANALYTICS HELPER KEYS
export const REFERRAL_CODE_SENT = 'referralCodeSent';
export const SEGMENT_ANONYMOUS_ID_LS_KEY = 'ajs_anonymous_id';
export const PESTO_ANONYMOUS_EVENT_SENT_LS_KEY = 'pesto_anonymous_event_sent';
export const PESTO_IDENTIFY_EVENT_SENT_LS_KEY = 'pesto_identify_event_sent';

// COOKIES CONSENT KEYS
export const CONSENT_SETTING_COOKIE_KEY = 'consentUserSettings';
export const CONSENT_BANNER_SHOWN_COOKIE_KEY = 'consentBannerShown';
export const USER_DATA_COOKIE_KEY = 'userDataPesto';

// APPRAISAL KEYS
export const QUICK_APPRAISAL_LS_KEY = 'quick_appraisal';
export const SIGN_UP_USER_DATA_LS_KEY = 'sign_up_user_data';
export const CASH_SECURED_APPLICATION_USER_FORM_LS_KEY = 'cash_secured_application';

// AB TEST KEYS
export const CASH_SECURED_APPLICATION_AB_TEST = 'cash_secured_application_ab_test';

// STORE KEYS
export const QUICK_APPRAISAL_STORE_LS_KEY = 'quick_appraisal_store';

// DEBUGGER
export const DEBUGGER_PROGRAM_LS_KEY = 'debuggerProgram';

export const DEBUGGER_LS_KEY = 'debuggerEnabled';
