import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import TextLink, { LinkType } from '@pesto/hubble-shared/components/UI/TextLink/TextLink';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { TriangleAlert } from 'lucide-react';

import { useGoRoutes } from '../hooks/useGoRoutes';
import { routes } from '../router/routes';

interface ErrorPageProps {
  error?: string;
}

export const ErrorPage = ({ error = 'An unexpected error has occurred' }: ErrorPageProps) => {
  const { navigate } = useGoRoutes();

  return (
    <div className="flex min-h-[50vh] w-full flex-col items-center justify-center gap-6 p-6 text-center">
      <TriangleAlert className="h-32 w-32 text-yellow-500" />

      <div className="space-y-2">
        <Typography variant="heroSmall" className="text-4xl font-semibold">
          Unexpected Error
        </Typography>

        <Typography variant="body" className="max-w-md text-gray-600">
          {error}
        </Typography>
      </div>
      <div className="flex flex-col gap-2">
        <Button buttonType="primary" text="Refresh Page" onClick={() => window.location.reload()} className="!w-auto" />
        <Typography variant="body" className="max-w-md text-gray-600">
          or
        </Typography>
        <TextLink as={LinkType.Button} onClick={() => navigate(routes.home)} className="!w-auto">
          Return to Homepage
        </TextLink>
      </div>
    </div>
  );
};
