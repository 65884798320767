import { MONETARY_LIMITS } from '@pesto/hubble-shared/constants/numeric-constants';
import Calendar from '@pesto/hubble-shared/icons/components/split/Calendar';
import Card from '@pesto/hubble-shared/icons/components/split/Card';
import Coins from '@pesto/hubble-shared/icons/components/split/Coins';
import Speed from '@pesto/hubble-shared/icons/components/split/Speed';
import Toggle from '@pesto/hubble-shared/icons/components/split/Toggle';
import type { ComponentType, ReactNode } from 'react';

interface CustomIconProps {
  className?: string;
}

export interface TextItem {
  title: string;
  description: string | ReactNode | null;
  icon: ComponentType<CustomIconProps>;
}

export const headerItems: TextItem[] = [
  {
    title: 'Convenient Progress',
    description: `Make $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} payments until you reach the $200 minimum deposit.`,
    icon: Toggle,
  },
  {
    title: 'Flexible Timing',
    description: 'Pay weekly, bi-weekly, or monthly, your choice.',
    icon: Calendar,
  },
  {
    title: 'Lower Risk, Stronger Credit',
    description: 'Low-risk option that helps you establish or rebuild your credit history with confidence.',
    icon: Speed,
  },
  {
    title: `One-time $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} processing fee`,
    description: `Added to your initial $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} payment.`,
    icon: Coins,
  },
];

export const footerItems: TextItem[] = [
  {
    title: `Make your first $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} payment`,
    description: (
      <div className="cursor-pointer">
        Pay the ${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} processing fee - to start building credit. <br />
        <div className="mt-2 underline">Get Started with the $20 payment</div>
      </div>
    ),
    icon: Card,
  },
];

export const creditLineItems: TextItem[] = [
  {
    title: 'Lower Risk, Stronger Credit',
    description: `Low-risk option that helps you establish or rebuild your credit history with confidence.`,
    icon: Speed,
  },
  {
    title: `One-time $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} processing fee`,
    description: null,
    icon: Coins,
  },
  {
    title: 'Convenient Progress: ',
    description: `Make payments as low as $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} until you reach the $${MONETARY_LIMITS.CREDIT.MIN_AMOUNT.NON_CARDHOLDER} minimum deposit.`,
    icon: Toggle,
  },
  {
    title: 'Flexible Timing',
    description: `Pay at a pace that works for you. You have up to one year to make the $${MONETARY_LIMITS.CREDIT.MIN_AMOUNT.NON_CARDHOLDER} minimum payment.`,
    icon: Coins,
  },
];

export const onboardingFundFullDeposit: TextItem[] = [
  {
    title: 'Immediate Access:',
    description: `Pay the full minimum $${MONETARY_LIMITS.CREDIT.MIN_AMOUNT.NON_CARDHOLDER} deposit now to secure your card instantly.`,
    icon: Card,
  },
  {
    title: `Fast-Track:`,
    description: 'Jumpstart your credit-building journey without delay.',
    icon: Coins,
  },
];

export const onboardingPestoOverTime: TextItem[] = [
  {
    title: 'Convenient Progress:',
    description: `Make $${MONETARY_LIMITS.MINIMUM_PAYMENT_AMOUNT} payments until you reach the $${MONETARY_LIMITS.CREDIT.MIN_AMOUNT.NON_CARDHOLDER} minimum deposit.`,
    icon: Toggle,
  },
  {
    title: `Flexible Timing:`,
    description: `Pay at a pace that works for you. You have up to one year to make the $${MONETARY_LIMITS.CREDIT.MIN_AMOUNT.NON_CARDHOLDER} minimum payment.`,
    icon: Calendar,
  },
];
