type EnvType = 'local' | 'development' | 'production' | 'staging' | 'test' | 'exp';
export const ReactEnv: Record<string, EnvType> = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
  TEST: 'test',
  EXP: 'exp',
};
export const ReactAppEnv = import.meta.env.VITE_ENV as EnvType;
export const ReactAppEnvIsProd = ReactAppEnv === ReactEnv.PRODUCTION;
export const ReactAppEnvIsStaging = ReactAppEnv === ReactEnv.STAGING;
export const ReactAppEnvIsNotProd = ReactAppEnv !== ReactEnv.PRODUCTION;

export const ReactProgramEnv = import.meta.env.VITE_PROGRAM;
