import type { ReactNode } from 'react';
import type { ToastContainerProps } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { UI_CONFIG } from '../constants/numeric-constants';

interface ToastProviderProps {
  children: ReactNode;
}

const defaultProps: ToastContainerProps = {
  position: 'top-right',
  pauseOnHover: true,
  theme: 'light',
  autoClose: UI_CONFIG.TOAST.AUTO_CLOSE_MS,
  limit: UI_CONFIG.TOAST.MAX_COUNT,
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  return (
    <>
      <ToastContainer {...defaultProps} />
      {children}
    </>
  );
};
