import { RetryLink } from '@apollo/client/link/retry';

import { API_CONFIG } from '../../constants/numeric-constants';

export const useHandleRetries = () => {
  return new RetryLink({
    delay: {
      initial: API_CONFIG.RETRY.INITIAL_DELAY_MS,
      max: API_CONFIG.RETRY.MAX_DELAY_MS,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      const isRetryAllowed =
        error?.networkError &&
        API_CONFIG.RETRY.STATUS_CODES.includes(error.networkError.statusCode) &&
        count <= API_CONFIG.RETRY.MAX_ATTEMPTS;

      if (isRetryAllowed) {
        console.warn('[errorNetwork] - retrying', operation.operationName, error);
      } else {
        console.warn('[errorNetwork] - not retrying', operation.operationName, error, error?.networkError?.statusCode);
      }

      return isRetryAllowed;
    },
  });
};
