import { DebuggerHelper } from './components/DebuggerHelper';
import { ProgramDebugger } from './components/ProgramDebugger';
import { UsefulCopyPaste } from './components/UsefulCopyPaste';

export const AdminDebuggerContent = () => {
  return (
    <div className="flex flex-col gap-4 p-6">
      <ProgramDebugger />
      <DebuggerHelper />
      <UsefulCopyPaste />
    </div>
  );
};
