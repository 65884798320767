import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import type { FC } from 'react';

interface SessionExpiredModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SessionExpiredModal: FC<SessionExpiredModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <Typography variant="headerSmall">Session has ended</Typography>
        <Typography variant="body">
          Your session has expired. For security reasons, we've logged you out. Please log in again to continue.
        </Typography>
        <Button
          buttonType="secondary"
          text="Sign In"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </Modal2>
  );
};

export default SessionExpiredModal;
