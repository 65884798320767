import type { FC, ReactNode } from 'react';

import { cn } from '../../../utils/cn';

export enum LinkType {
  Anchor = 'a',
  RouterLink = 'Link',
  Button = 'button',
}

export type TextLinkProps = {
  as: LinkType;
  className?: string;
  children: ReactNode;
  onClick?: (arg: any) => void;
  LinkComponent?: FC<any>;
} & (
  | {
      as: LinkType.Anchor;
      href: string;
      target?: HTMLAnchorElement['target'];
    }
  | {
      as: LinkType.RouterLink;
      href: string;
      target?: HTMLAnchorElement['target'];
    }
  | {
      as: LinkType.Button;
      href?: never;
      target?: HTMLAnchorElement['target'];
      type?: 'button' | 'submit' | 'reset';
    }
);

const TextLink: FC<TextLinkProps> = props => {
  const { as, children, className, onClick, LinkComponent } = props;
  const commonClasses = cn('text-action underline hover:no-underline', className);

  if (as === LinkType.Anchor && 'href' in props) {
    return (
      <a className={commonClasses} target={props.target} href={props.href} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (as === LinkType.RouterLink && 'href' in props) {
    if (LinkComponent) {
      return (
        <LinkComponent className={commonClasses} onClick={onClick} href={props.href}>
          {children}
        </LinkComponent>
      );
    }
    // Fallback to regular anchor when LinkComponent is not provided
    return (
      <a className={commonClasses} target={props.target} href={props.href} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (as === LinkType.Button) {
    return (
      <button className={commonClasses} onClick={onClick} type={props.type}>
        {children}
      </button>
    );
  }

  return null;
};

export default TextLink;
