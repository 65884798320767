import { useAnalytics } from '@pesto/hubble-shared/providers/analytics/AnalyticsProvider';
import { setConsentOnPageChange } from '@pesto/hubble-shared/utils/consentHelper';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useReactRouterPageListener = () => {
  const { analytics } = useAnalytics();
  const location = useLocation();
  const previousPathname = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== previousPathname.current) {
      previousPathname.current = location.pathname;

      setConsentOnPageChange();
      analytics.page();
    }
  }, [analytics, location.pathname]);
};

export default useReactRouterPageListener;
