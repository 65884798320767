const TAPFILIATE_SCRIPT_ID = 'tapfiliate-script';

const loadTapfiliate = async () => {
  return new Promise<any>((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = TAPFILIATE_SCRIPT_ID;
    script.onload = () => {
      resolve('Tapfiliate script loaded successfully.');
    };
    script.onerror = error => {
      reject(error);
    };
    script.src = 'https://script.tapfiliate.com/tapfiliate.js';
    document.body.appendChild(script);
  });
};

const initTapfiliate = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
    (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
    tap('create', '45142-398c15', { integration: "javascript" });
    tap('detect')
    `;

  document.body.appendChild(script);
};

export const initializeTapfiliate = () => {
  if (document.getElementById(TAPFILIATE_SCRIPT_ID)) {
    initTapfiliate();
  } else {
    loadTapfiliate()
      .then(() => {
        initTapfiliate();
      })
      .catch(error => {
        console.error('Error loading Tapfiliate script', error);
      });
  }
};
