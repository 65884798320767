import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

import TextLink, { LinkType } from '../UI/TextLink/TextLink';

interface ConsentCookiesProps {
  onAccept: () => void;
  onClose: () => void;
  onSettingsClick?: () => void;
}

export const ConsentCookies = (props: ConsentCookiesProps) => {
  const { onAccept, onClose, onSettingsClick } = props;

  return (
    <div
      id="cookie-banner"
      className="fixed inset-x-0 bottom-0 z-[99999] flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8"
    >
      <Typography variant={'caption'} className="max-w-4xl text-sm leading-6 text-gray-900">
        This website uses technologies such as cookies and pixels to enable essential site functionality, as well for
        analytics, personalization and marketing purposes. You can manage your cookie preferences by clicking{' '}
        <button className="text-action inline w-auto underline hover:no-underline" onClick={onSettingsClick}>
          Cookie settings
        </button>{' '}
        See our{' '}
        <TextLink
          as={LinkType.Anchor}
          href="/legal/privacy-policy#:~:text=confirm%20your%20identity.-,Use%20of%20Cookies,-A%20cookie%20is"
          className="text-action inline w-auto underline hover:no-underline"
        >
          cookie policy
        </TextLink>
        .
      </Typography>
      <div className="flex flex-none items-center gap-x-5">
        <Button buttonType={'primary'} text={'Accept'} onClick={onAccept} />
        <Button buttonType={'secondary'} text={'Close'} onClick={onClose} />
      </div>
    </div>
  );
};
