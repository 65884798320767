import { forwardRef, useEffect, useState } from 'react';

import { useIsMobile } from '../../../hooks/useIsMobile';
import CheckboxCheckIcon from '../../../icons/components/CheckboxCheckIcon';
import { cn } from '../../../utils/cn';
import { Typography } from '../Typography';

import type { CheckBoxProps } from './CheckBoxTypes';
import { generateRootStyle } from './generateRootStyle';

interface CheckBoxComponentProps {
  isChecked: boolean;
  size: { width: string; height: string };
  rootStyle: string;
  handleCheckboxClick: () => void;
  dataTestId?: string;
}
const CheckBoxComponent = ({ isChecked, size, rootStyle, handleCheckboxClick, dataTestId }: CheckBoxComponentProps) => {
  return (
    <div
      onClick={handleCheckboxClick}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCheckboxClick();
          e.preventDefault();
        }
      }}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      data-testid={dataTestId}
    >
      <div style={{ width: size.width, height: size.height }} className={cn(rootStyle, 'transition-all duration-0')}>
        {isChecked ? <CheckboxCheckIcon className="text-white" /> : null}
      </div>
    </div>
  );
};

const CheckBox = forwardRef<HTMLDivElement, CheckBoxProps>((props, ref) => {
  const {
    className,
    controlled = true,
    checked = false,
    size = { width: '20px', height: '20px' },
    error,
    onChange,
    value = false,
    dataTestId,
  } = props;

  const isMobile = useIsMobile();
  const [isChecked, setIsChecked] = useState<boolean>(controlled ? value : checked);

  useEffect(() => {
    if (controlled) {
      setIsChecked(value);
    } else {
      setIsChecked(checked);
    }
  }, [controlled, checked, value]);

  const handleCheckboxClick = () => {
    const newValue = !isChecked;
    if (!controlled) {
      setIsChecked(newValue);
    }
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const rootStyle = generateRootStyle({
    className,
    isChecked: isChecked,
    size,
  });

  if (!controlled) {
    return (
      <CheckBoxComponent
        isChecked={isChecked}
        size={size}
        rootStyle={rootStyle}
        handleCheckboxClick={handleCheckboxClick}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <div className="flex gap-2" ref={ref} onClick={handleCheckboxClick}>
      <CheckBoxComponent
        isChecked={isChecked}
        size={size}
        rootStyle={rootStyle}
        handleCheckboxClick={handleCheckboxClick}
        dataTestId={dataTestId}
      />

      <div className="text-md mt-0.5">
        <Typography className="cursor-pointer" variant={isMobile ? 'captionSmall' : 'bodySmall'}>
          {props.text}
        </Typography>
        {error && <div className="text-danger text-sm">{error}</div>}
      </div>
    </div>
  );
});
CheckBox.displayName = 'CheckBox';
export default CheckBox;
