import { LocalStorage } from '@pesto/hubble-shared/utils/core/BrowserStorage';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { Program } from '../config/program';

export type ColorSchemeValue = string;
export type ThemeProviderProps = {
  children: ReactNode;
  storageKey?: string;
  colorSchemeStorageKey?: string;
  overrideColorScheme?: Program;
};

type ThemeProviderState = {
  colorScheme: ColorSchemeValue;
  setColorScheme: (scheme: ColorSchemeValue) => void;
};

const initialState: ThemeProviderState = {
  colorScheme: Program.HOMELIGHT,
  setColorScheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  colorSchemeStorageKey = 'ui-color-scheme',
  overrideColorScheme = Program.HOMELIGHT,
  ...props
}: ThemeProviderProps) {
  const [colorScheme, setColorSchemeState] = useState<ColorSchemeValue>(() => {
    const storedScheme = LocalStorage.getItem(colorSchemeStorageKey) as ColorSchemeValue;
    return overrideColorScheme || storedScheme || Program.HOMELIGHT;
  });

  const root = window.document.documentElement;

  root.classList.add(colorScheme);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove(Program.HOMELIGHT);

    root.classList.add(colorScheme);
  }, [colorScheme]);

  const value: ThemeProviderState = {
    colorScheme,
    setColorScheme: (scheme: ColorSchemeValue) => {
      LocalStorage.setItem(colorSchemeStorageKey, scheme);
      setColorSchemeState(scheme);
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error('useTheme must be used within a ThemeProvider');

  return context;
};
