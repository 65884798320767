const CLOUDFLARE_LEGAL_URL = 'https://files.getpesto.com/legal';

export const routes = {
  home: '/',
  cash: '/cash',
  createAccount: '/login/create-account',
  register: '/login/register',
  faq: '/faq',
  about: '/about',
  compareToPawns: '/compare-to-pawn-loans',
  login: '/login',
  loginCallback: '/login-callback',
  marketing: '/marketing',
  signUp: '/sign-up',
  cardservicingContactUs: '/cardservicing/contact-us',
  operatingSystem: '/operating-system',
  contactb2b: '/contact/b2b',
  jewelry: '/jewelry',
};
export const appRoutes = {
  app: '/app',
  settings: '/app/settings',
  quickAppraisal: '/app/quick-appraisal',
  application: '/app/application',
  cashSecured: '/app/cash-secured',
  homeLight: '/app/homelight',
  status: '/app/status',
  appLogin: '/app/login',
  applicationStatus: '/app/application/status',
  cashSecuredStatus: '/app/cash-secured/status',
  applicationOffer: '/app/application/offer',
  preQualified: '/app/application/pre-qualified',
  docVerification: '/app/doc-verification',
  loginCallback: '/login-callback',
  landingFinder: '/landing',
  login: '/login',
  signUp: '/login/register',
  faq: '/faq',
};

export const links = {
  talkToTheTeam: 'https://calendly.com/jamespestocustomercalls/30min',
  marketingWebsite: 'https://www.getpesto.com',
  privacyPolicy: `${CLOUDFLARE_LEGAL_URL}/Pesto-Privacy-Policy.pdf`,
  privacyNotice: `${CLOUDFLARE_LEGAL_URL}/Pesto-Privacy-Notice.pdf`,
  termsOfService: `${CLOUDFLARE_LEGAL_URL}/Pesto-Terms-of-Service.pdf`,
  rewardsTerms: `${CLOUDFLARE_LEGAL_URL}/Pesto-Mastercard-Rewards-Program-Terms-and-Conditions.pdf`,
  eSignDisclosure: `${CLOUDFLARE_LEGAL_URL}/Pesto-E-Sign-Disclosure-and-Consent-to-Use-Electronic-Records-and-Signatures.pdf`,
  tcpa: `${CLOUDFLARE_LEGAL_URL}/Pesto-Telephone-Consumer-Protection-Act-Notice.pdf`,
  cardholderAgreement: `${CLOUDFLARE_LEGAL_URL}/Pesto-Consumer-Cardholder-Agreement.pdf`,
  packingAndShippingInstructions: `${CLOUDFLARE_LEGAL_URL}/Pesto-Preparing-your-Valuables-for-Shipment.pdf`,
  securityAgreementItem: `${CLOUDFLARE_LEGAL_URL}/Security-Agreement-Item.pdf`,
  securityAgreementCash: `${CLOUDFLARE_LEGAL_URL}/Security-Agreement-Cash.pdf`,
  patriotAct: `${CLOUDFLARE_LEGAL_URL}/Pesto-Patriot-Act-Notice.pdf`,
  rateDisclosure: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-other-Cost-Information.pdf`,
  rateDisclosureItem: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Item.pdf`,
  rateDisclosureCash: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Cash.pdf`,
  accountOpeningDisclosures: `${CLOUDFLARE_LEGAL_URL}/TILA-Disclosures-Account-Opening.pdf`,
  appStoreUrl: 'https://apps.apple.com/us/app/pesto-credit/id1669664225',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.i2c.mcpcc.pinglendv32',
  cardholderLogin: 'https://cardservicing.getpesto.com/cholder/welcome',
  nerdWalletArticle: 'https://www.nerdwallet.com/article/credit-cards/how-much-secured-card-deposit',
  rewardsTermsAndConditions: `${CLOUDFLARE_LEGAL_URL}/Pesto-Mastercard-Rewards-Program-Terms-and-Conditions.pdf`,
};

export const social = {
  facebook: 'https://www.facebook.com/pestomastercard',
  instagram: 'https://www.instagram.com/getpesto/',
  linkedin: 'https://www.linkedin.com/company/pesto',
  twitter: 'https://x.com/getpesto',
  tiktok: 'https://www.tiktok.com/@getpesto',
};

const exactRoutesHelper = (routes: Record<string, string>, baseRoute: string) =>
  Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: routes[key as keyof typeof routes].replace(baseRoute, ''),
    }),
    {} as typeof routes,
  );

export const quickAppraisalRoutes = {
  metal: appRoutes.quickAppraisal + '/metal',
  gemstone: appRoutes.quickAppraisal + '/gemstone',
  ring: appRoutes.quickAppraisal + '/ring',
  earrings: appRoutes.quickAppraisal + '/earrings',
  necklace: appRoutes.quickAppraisal + '/necklace',
  watch: appRoutes.quickAppraisal + '/watch',
  other: appRoutes.quickAppraisal + '/other',
};

export const quickAppraisalRoutesExact = exactRoutesHelper(quickAppraisalRoutes, appRoutes.quickAppraisal);

export const loginRoutes = {
  root: appRoutes.appLogin + '/',
  personalInfo: appRoutes.appLogin + '/personal-info',
};

export const loginRoutesExact = exactRoutesHelper(loginRoutes, appRoutes.appLogin);

export const applicationRoutes = {
  root: appRoutes.application + '/',
  quickAppraisal: appRoutes.application + '/quick-appraisal',
  signUp: appRoutes.application + '/sign-up',
  itemsCart: appRoutes.application + '/asset-cart',
  addressAndIncome: appRoutes.application + '/address-and-income',
  itemInReview: appRoutes.application + '/item-in-review',
  preQualified: appRoutes.application + '/pre-qualified',
  itemsShipped: appRoutes.application + '/items-shipped',
  itemsDelivered: appRoutes.application + '/items-delivered',
  offer: appRoutes.application + '/offer',
  completion: appRoutes.application + '/completion',
};
export const applicationRoutesExact = exactRoutesHelper(applicationRoutes, appRoutes.application);

export const userStatusRoutes = {
  inReview: appRoutes.applicationStatus + '/in-review',
  manualReview: appRoutes.applicationStatus + '/manual-review',
  creditFreeze: appRoutes.applicationStatus + '/credit-freeze',
  offerRejected: appRoutes.applicationStatus + '/offer-rejected',
  notPrequalified: appRoutes.applicationStatus + '/not-prequalified',
  creditDenied: appRoutes.applicationStatus + '/credit-denied',
  invalidState: appRoutes.applicationStatus + '/invalid-state',
  dontOperateInState: appRoutes.applicationStatus + '/dont-operate-in-state',
  loginToYourAccount: appRoutes.applicationStatus + '/login-to-your-account',
};
export const userStatusRoutesExact = exactRoutesHelper(userStatusRoutes, appRoutes.applicationStatus);

export const offerRoutes = {
  root: appRoutes.applicationOffer + '/',
  rejectOffer: appRoutes.applicationOffer + '/reject-offer',
  verifyInfo: appRoutes.applicationOffer + '/verify-personal-info',
  verifyIncome: appRoutes.applicationOffer + '/verify-income-info',
  finalAgreements: appRoutes.applicationOffer + '/final-agreements',
};

export const offerRoutesExact = exactRoutesHelper(offerRoutes, appRoutes.applicationOffer);

export const preQualifiedRoute = {
  shipping: appRoutes.preQualified + '/',
  downloadLabel: appRoutes.preQualified + '/download-label',
  shippingKit: appRoutes.preQualified + '/shipping-kit',
  shippingCode: appRoutes.preQualified + '/shipping-code',
};

export const preQualifiedRoutesExact = exactRoutesHelper(preQualifiedRoute, appRoutes.preQualified);

export const settingsRoutes = {
  root: appRoutes.settings + '/',
  personalInfo: appRoutes.settings + '/personal-info',
  myItems: appRoutes.settings + '/my-items',
};

export const settingsRoutesExact = exactRoutesHelper(settingsRoutes, appRoutes.settings);

export const homeLightRoutes = {
  root: appRoutes.homeLight + '/',
  personalInfo: appRoutes.homeLight + '/personal-info',
  offer: appRoutes.homeLight + '/offer',
  cancelOffer: appRoutes.homeLight + '/cancel-offer',
  verifyInfo: appRoutes.homeLight + '/verify-info',
  verifyIncome: appRoutes.homeLight + '/verify-income',
  agreements: appRoutes.homeLight + '/agreements',
  congratulations: appRoutes.homeLight + '/congratulations',
  status: appRoutes.homeLight + '/status',
};

export const homeLightRoutesExact = exactRoutesHelper(homeLightRoutes, appRoutes.homeLight);

export const cashSecuredRoutes = {
  root: appRoutes.cashSecured + '/',
  creditLine: appRoutes.cashSecured + '/credit-line',
  personalInfo: appRoutes.cashSecured + '/personal-info',
  paymentSelection: appRoutes.cashSecured + '/payment-selection',
  fundOverTime: appRoutes.cashSecured + '/fund-over-time',
  payment: appRoutes.cashSecured + '/payment',
  verifyPayment: appRoutes.cashSecured + '/verify-payment',
  agreements: appRoutes.cashSecured + '/agreements',
  congratulations: appRoutes.cashSecured + '/congratulations',
  status: appRoutes.cashSecured + '/status',
};

export const cashSecuredRoutesExact = exactRoutesHelper(cashSecuredRoutes, appRoutes.cashSecured);

export const landingPageRoutes = {
  root: appRoutes.landingFinder + '/finder',
  newSignUpDemo: appRoutes.landingFinder + '/new-sign-up-demo',
  personalInfoDemo: appRoutes.landingFinder + '/personal-info-demo',
};

export const landingPageRoutesExact = exactRoutesHelper(landingPageRoutes, appRoutes.landingFinder);

export const cashPaymentRoutes = {
  root: cashSecuredRoutes.payment + '/',
  fundNow: cashSecuredRoutes.payment + '/fund-now',
  fundOverTime: cashSecuredRoutes.payment + '/fund-over-time',
};

export const cashPaymentRoutesExact = exactRoutesHelper(cashPaymentRoutes, cashSecuredRoutes.payment);

export const cashSecuredStatusRoutes = {
  root: appRoutes.cashSecuredStatus + '/',
  inReview: appRoutes.cashSecuredStatus + '/in-review',
  offerRejected: appRoutes.cashSecuredStatus + '/offer-rejected',
  invalidState: appRoutes.cashSecuredStatus + '/invalid-state',
  notPrequalified: appRoutes.cashSecuredStatus + '/not-prequalified',
  manualReview: appRoutes.cashSecuredStatus + '/manual-review',
  creditFreeze: appRoutes.cashSecuredStatus + '/credit-freeze',
  loginToYourAccount: appRoutes.cashSecuredStatus + '/login-to-your-account',
};

export const cashSecuredStatusRoutesExact = exactRoutesHelper(cashSecuredStatusRoutes, appRoutes.cashSecuredStatus);
