const stripStartingSlash = (path: string) => (path.startsWith('/') ? path.slice(1) : path);

const exactRoutesHelper = (routes: Record<string, string>, baseRoute: string) =>
  Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: stripStartingSlash(routes[key as keyof typeof routes].replace(baseRoute, '')),
    }),
    {} as typeof routes,
  );

export const routes = {
  home: '/',
  login: '/login',
  quickAppraisal: '/quick-appraisal',
  app: '/app',
  signUp: '/sign-up',

  // login screens
  loginCallback: '/login-callback',
  loginGetUser: '/login-get-user',
  skipAddItemSignUp: '/skip-add-item-sign-up',
  addItemSignUp: '/add-item-sign-up',
};

export const appRoutes = {
  home: '/app',
  personalInfo: '/app/personal-info',
  offer: '/app/offer',
  cancelOffer: '/app/cancel-offer',
  agreements: '/app/agreements',
  congratulations: '/app/congratulations',
  status: '/app/status',
  personalInfoMissing: '/app/personal-info-missing',

  // Item flow
  quickAppraisal: '/app/quick-appraisal',
  itemsCart: '/app/items-cart',
  itemInReview: '/app/item-in-review',
  preQualified: '/app/pre-qualified',
  itemsShipped: '/app/items-shipped',

  // Cash secured flow
  creditLine: '/app/credit-line',
  fundOverTime: '/app/fund-over-time',
  payment: '/app/payment',
  paymentSelection: '/app/payment-selection',
  verifyPayment: '/app/verify-payment',

  // Payment frame
  paymentIframe: '/app/frame/payment',
};

export const appRoutesExact = exactRoutesHelper(appRoutes, routes.app);

export const offerRoutes = {
  root: appRoutes.offer + '/',
  rejectOffer: appRoutes.offer + '/reject-offer',
  verifyInfo: appRoutes.offer + '/verify-personal-info',
  verifyIncome: appRoutes.offer + '/verify-income-info',
  finalAgreements: appRoutes.offer + '/final-agreements',
};

export const offerRoutesExact = exactRoutesHelper(offerRoutes, appRoutes.offer);

export const preQualifiedRoute = {
  shipping: appRoutes.preQualified + '/',
  downloadLabel: appRoutes.preQualified + '/download-label',
  shippingKit: appRoutes.preQualified + '/shipping-kit',
  shippingCode: appRoutes.preQualified + '/shipping-code',
};

export const preQualifiedRoutesExact = exactRoutesHelper(preQualifiedRoute, appRoutes.preQualified);

export const loginRoutes = {
  signUp: routes.login + '/sign-up',
  createAccount: routes.login + '/create-account',
};

export const loginRoutesExact = exactRoutesHelper(loginRoutes, routes.login);

export const statusRoutes = {
  inReview: appRoutes.status + '/in-review',
  manualReview: appRoutes.status + '/manual-review',
  creditFreeze: appRoutes.status + '/credit-freeze',
  offerRejected: appRoutes.status + '/offer-rejected',
  notPrequalified: appRoutes.status + '/not-prequalified',
  creditDenied: appRoutes.status + '/credit-denied',
  invalidState: appRoutes.status + '/invalid-state',
  dontOperateInState: appRoutes.status + '/dont-operate-in-state',
  loginToYourAccount: appRoutes.status + '/login-to-your-account',
};

export const statusRoutesExact = exactRoutesHelper(statusRoutes, appRoutes.status);

export const quickAppraisalRoutes = {
  metal: appRoutes.quickAppraisal + '/metal',
  gemstone: appRoutes.quickAppraisal + '/gemstone',
  ring: appRoutes.quickAppraisal + '/ring',
  earrings: appRoutes.quickAppraisal + '/earrings',
  necklace: appRoutes.quickAppraisal + '/necklace',
  watch: appRoutes.quickAppraisal + '/watch',
  other: appRoutes.quickAppraisal + '/other',
};

export const quickAppraisalRoutesExact = exactRoutesHelper(quickAppraisalRoutes, appRoutes.quickAppraisal);

export const cashPaymentRoutes = {
  root: appRoutes.payment + '/',
  fundNow: appRoutes.payment + '/fund-now',
  fundOverTime: appRoutes.payment + '/fund-over-time',
};

export const cashPaymentRoutesExact = exactRoutesHelper(cashPaymentRoutes, appRoutes.payment);
