import { useOnMount } from '@pesto/hubble-shared/hooks/useOnMount';
import { removeOldUtmParams } from '@pesto/hubble-shared/utils/URL/oldUtmParams';
import { removeUtmParams } from '@pesto/hubble-shared/utils/URL/utmParamsHelper';
import {
  type ConsentData,
  setConsent,
  setConsentAllGranted,
  setConsentBannerShown,
} from '@pesto/hubble-shared/utils/consentHelper';
import { getCookies, removeCookie } from '@pesto/hubble-shared/utils/cookies';
import { useState } from 'react';

import { CONSENT_SETTING_COOKIE_KEY, USER_DATA_COOKIE_KEY } from '../../constants/local-storage-keys';

import { ConsentCookies } from './ConsentCookies';
import { CookiesSettingsModal } from './CookiesSettingsModal';

interface CookiesConnectedProps {
  doNotShowOnPages?: string[];
}

export const CookiesConnected = ({ doNotShowOnPages }: CookiesConnectedProps) => {
  const pathname = window.location.pathname;
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(getCookies(CONSENT_SETTING_COOKIE_KEY) !== null);

  const hideBanner = consentAccepted || doNotShowOnPages?.includes(pathname);

  const handleSave = (cookies: ConsentData) => {
    setConsentAccepted(true);
    if (!cookies.performance) {
      removeUtmParams();
      removeOldUtmParams();
      removeCookie(USER_DATA_COOKIE_KEY);
    }
    setConsent(cookies);
  };

  const handleAccept = () => {
    setConsentAllGranted();
    setConsentAccepted(true);
  };

  const handleReject = () => {
    setConsentAllGranted();
    setConsentAccepted(true);
  };

  const handleSettingsClick = () => {
    setSettingsModalOpen(!settingsModalOpen);
  };

  useOnMount(() => {
    if (!hideBanner) {
      setConsentBannerShown();
    }
  });

  if (hideBanner) return null;

  return (
    <>
      <ConsentCookies onAccept={handleAccept} onClose={handleReject} onSettingsClick={handleSettingsClick} />
      <CookiesSettingsModal
        isOpen={settingsModalOpen}
        onClose={handleSettingsClick}
        onSave={cookies => handleSave(cookies)}
      />
    </>
  );
};
