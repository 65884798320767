import type { QueryHookOptions } from '@apollo/client';

import { useGetItemCartQuery, type Exact, type GetItemCartQuery } from '@pesto/hubble-shared/__generated__/graphql/api';

type LoadingConditions = QueryHookOptions<
  GetItemCartQuery,
  Exact<{
    [key: string]: never;
  }>
>;

export const useFetchItems = (loadingConditions: LoadingConditions) => {
  const { data: items, loading } = useGetItemCartQuery(loadingConditions);
  return { items, loading };
};
