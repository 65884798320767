import type { ApolloClientConfig } from '@pesto/hubble-shared/api/Apollo';
import { QUICK_APPRAISAL_STORE_LS_KEY } from '@pesto/hubble-shared/constants/local-storage-keys';
import { AdminDebugger } from '@pesto/hubble-shared/features/AdminDebugger/AdminDebugger';
import type { AuthConfig } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider.types';
import { HubbleSharedProvider } from '@pesto/hubble-shared/providers/HubbleSharedProvider';
import { HelmetProvider } from 'react-helmet-async';

import './App.css';
import { AdminDebuggerContent } from './components/features/AdminDebuggerContent/AdminDebuggerContent';
import { Favicon } from './components/shared/Favicon';
import { MetaTags } from './components/shared/MetaTags';
import { getProgram } from './config/programHelper';
import { ReactAppEnv, ReactEnv } from './constants/global';
import { AppProvider } from './providers/AppProvider';
import ErrorBoundary from './providers/ErrorBoundary';
import { ExternalScriptsLoadProvider } from './providers/ExternalScriptsLoadProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import { UserProvider } from './providers/UserProvider';
import RouterProvider from './router/RouterProvider';
import { applyProgramTheme } from './utils/theme/themeHelper';

const apolloConfig: ApolloClientConfig = {
  directUri: import.meta.env.VITE_APP_URI ?? '',
  proxyUri: import.meta.env.VITE_VGS_INBOUND_URI ?? '',
  path: import.meta.env.VITE_GQL_PATH ?? '',
};

const authConfig: AuthConfig = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN ?? '',
  clientID: import.meta.env.VITE_AUTH0_CLIENT_ID ?? '',
  audience: import.meta.env.VITE_AUTH0_AUDIENCE ?? '',
  redirectUri: import.meta.env.VITE_AUTH0_REDIRECT_URI + '/login-callback' || '',
};

const isProd = ReactAppEnv === ReactEnv.PRODUCTION;

applyProgramTheme();

function App() {
  return (
    <>
      <HelmetProvider>
        <Favicon />
        <MetaTags />
        <ErrorBoundary>
          <ExternalScriptsLoadProvider>
            <HubbleSharedProvider
              apolloConfig={apolloConfig}
              authConfig={authConfig}
              isProd={isProd}
              quickAppraisalStorageKey={QUICK_APPRAISAL_STORE_LS_KEY}
              environmentVariables={{
                segmentApiKey: import.meta.env.VITE_SEGMENT_KEY,
                pestoAnalyticsUrl: import.meta.env.VITE_PESTO_ANALYTICS_URL,
                googleMapsApiKey: import.meta.env.VITE_GOOGLE_AUTO_COMPLETE_KEY,
              }}
            >
              <AppProvider>
                <UserProvider>
                  <ThemeProvider overrideColorScheme={getProgram()}>
                    <AdminDebugger content={<AdminDebuggerContent />} />
                    <RouterProvider />
                  </ThemeProvider>
                </UserProvider>
              </AppProvider>
            </HubbleSharedProvider>
          </ExternalScriptsLoadProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </>
  );
}

export default App;
