import { CookiesConnected } from '@pesto/hubble-shared/components/ConsentCookies/CookiesConnected';
import { IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY } from '@pesto/hubble-shared/constants/local-storage-keys';
import { useOnMount } from '@pesto/hubble-shared/hooks/useOnMount';
import { getGoogleConsent, getUserConsent } from '@pesto/hubble-shared/utils/consentHelper';
import { LocalStorage } from '@pesto/hubble-shared/utils/core/BrowserStorage';
import { initSegment } from '@pesto/hubble-shared/utils/scripts/segmentScript';
import { initializeTapfiliate } from '@pesto/hubble-shared/utils/scripts/tapfilliateScript';
import { useEffect, type FC, type ReactNode } from 'react';

import DatadogInit from './DataDogInit';

typeof window !== 'undefined' &&
  window.addEventListener('load', () => {
    setTimeout(() => {
      initSegment(import.meta.env.VITE_SEGMENT_KEY);
    }, 1);
  });

const loadScript = (src: string, id: string, hidden = false): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    if (hidden) script.setAttribute('hidden', 'true');

    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));

    document.head.appendChild(script);
  });
};

const loadGoogleTagManager = (gtmId: string): void => {
  const script = document.createElement('script');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
  `;
  document.head.appendChild(script);
};

export const ExternalScriptsLoadProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isProd = window?.location.hostname === 'getpesto.com';
  const hasCashSecUrl = window?.location.href.includes('cash-secured-application');
  const debuggerToggle = LocalStorage.getItem(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY) === 'true';
  const shouldLoadGoogleTag = (isProd && !hasCashSecUrl) || debuggerToggle;
  const isEmbeddedInIframe = window.self !== window.top;
  const shouldLoadHubspotChat = !isEmbeddedInIframe;
  const { functional } = getUserConsent();

  getGoogleConsent();

  useEffect(() => {
    const loadScripts = async () => {
      try {
        await loadScript('https://js.dvnfo.com/devicer.min.js', 'devicer');

        if (shouldLoadHubspotChat) {
          await loadScript('https://js.hs-scripts.com/23749842.js', 'hubspot-chat', !shouldLoadHubspotChat);
        }

        if (shouldLoadGoogleTag) {
          loadGoogleTagManager('GTM-W2C7B8VH');
        }
      } catch (error) {
        console.error('Failed to load external scripts:', error);
      }
    };

    loadScripts();
  }, [shouldLoadHubspotChat, shouldLoadGoogleTag]);

  useOnMount(() => {
    if (functional) {
      initializeTapfiliate();
    }
  });

  return (
    <>
      <DatadogInit />
      <CookiesConnected />
      {children}
    </>
  );
};
