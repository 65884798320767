import type { UpdateIssues } from '@pesto/hubble-shared/__generated__/graphql/api';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import React from 'react';

import Warning from '../../icons/components/Warning';
import { updateIssueKey } from '../../utils/updateIssuesHelper';

export interface UpdateIssuesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignOut: () => void;
  updateIssues: UpdateIssues;
}

export const UpdateIssuesModal: React.FC<UpdateIssuesModalProps> = ({ isOpen, onClose, updateIssues, onSignOut }) => {
  const emailOrPhone = updateIssueKey(updateIssues);
  return (
    <Modal2 className="w-full" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <Warning className="mx-auto h-[64px] w-[64px]" />
        <Typography variant="headerMedium">This {emailOrPhone} is already associated with another account.</Typography>
        <Typography variant="body">
          To continue, please log in to that account or update your {emailOrPhone}.
        </Typography>
        <Button buttonType="secondary" text="Sign Out" onClick={onSignOut} />
        <Button buttonType="primary" text="Ok" onClick={onClose} />
      </div>
    </Modal2>
  );
};
