import { links } from '../../../hubble/src/constants/appRoutes';
import homeRoof from '../programs/HomeLight/assets/home-roof-2.png';
import homeLightLogo from '../programs/HomeLight/assets/the-card-logo.svg';
import homelightCardDesign from '../programs/HomeLight/assets/the-card.png';
import honorLogo from '../programs/Honor/assets/honor-lg.svg';
import honorBgImage from '../programs/Honor/assets/multicam-bg.svg';
import pestoLogo from '../programs/Pesto/assets/pesto-logo.svg';
import bgImage from '../programs/Safeguard/assets/login-bg.svg';
import safeguardLogo from '../programs/Safeguard/assets/safeguard-logo.svg';
import trovyLogo from '../programs/Trovy/assets/trovyLowResLogo.png';

import { createDefaultCashFlow, createDefaultHelocFlow, createDefaultItemFlow } from './createPartnerConfig';
import { Program } from './program';
import type { LayoutConfig, ProgramConfig } from './program.types';
import { programThemes } from './programTheme';

export const FlowType = {
  HELOC: 'heloc',
  CASH: 'cash',
  ITEM: 'item',
} as const;

const pestoLayout: LayoutConfig = {
  header: {
    theme: 'light',
  },
  loginPage: {
    bgColor: '0 0% 20%',
  },
};

export const programConfig: Record<Program, ProgramConfig> = {
  [Program.HOMELIGHT]: createDefaultHelocFlow({
    logo: homeLightLogo,
    cardDesign: homelightCardDesign,
    favicon: '/homelight-favicon.svg',
    meta: {
      title: 'The Card Application',
      description: 'The Card Application',
    },
    theme: programThemes[Program.HOMELIGHT],
    images: {
      loginBg: homeRoof,
      homeRoof: homeRoof,
    },
    layout: {
      loginPage: {
        bgColor: '#07142F',
      },
    },
    texts: {
      cardName: 'The Card',
      programName: 'HomeLight',
      customerSupport: {
        phoneNumber: '(888) 221-9804',
        email: 'support@homelight.com',
      },
    },
    legalLinks: {
      privacyNotice: links.privacyNotice,
      privacyPolicy: links.privacyPolicy,
      rateDisclosure: links.rateDisclosureItem,
      termsOfService: links.termsOfService,
      rewardTerms: links.rewardsTerms,
      cardholderAgreement: links.cardholderAgreement,
      securityAgreement: links.securityAgreementItem,
      patriotAct: links.patriotAct,
      accountOpeningDisclosures: links.accountOpeningDisclosures,
      eSignDisclosure: links.eSignDisclosure,
      tcpa: links.tcpa,
    },
    links: {
      appStoreUrl: links.appStoreUrl,
      playStoreUrl: links.playStoreUrl,
    },
  }),
  [Program.TROVY]: createDefaultHelocFlow({
    logo: trovyLogo,
    favicon: '/trovy-favicon.svg',
    meta: {
      title: 'Trovy Mastercard',
      description: 'Trovy Mastercard',
    },
    theme: programThemes[Program.TROVY],
    images: {
      loginBg: homeRoof,
      homeRoof: homeRoof,
    },
    texts: {
      cardName: 'Trovy Mastercard®',
      programName: 'Trovy',
      customerSupport: {
        phoneNumber: '(888) 221-9804',
        email: 'support@trovy.com',
      },
    },
    legalLinks: {
      privacyNotice: links.privacyNotice,
      privacyPolicy: links.privacyPolicy,
      rateDisclosure: links.rateDisclosureItem,
      termsOfService: links.termsOfService,
      rewardTerms: links.rewardsTerms,
      cardholderAgreement: links.cardholderAgreement,
      securityAgreement: links.securityAgreementItem,
      patriotAct: links.patriotAct,
      accountOpeningDisclosures: links.accountOpeningDisclosures,
      eSignDisclosure: links.eSignDisclosure,
      tcpa: links.tcpa,
    },
    links: {
      appStoreUrl: links.appStoreUrl,
      playStoreUrl: links.playStoreUrl,
    },
  }),
  [Program.SAFEGUARD]: createDefaultItemFlow({
    logo: safeguardLogo,
    favicon: '/safeguard-favicon.svg',
    theme: programThemes[Program.SAFEGUARD],
    meta: {
      title: 'Safeguard Mastercard',
      description: 'Safeguard Mastercard',
    },
    images: {
      loginBg: bgImage,
    },
    texts: {
      cardName: 'Safeguard Mastercard®',
      programName: 'Safeguard',
      customerSupport: {
        phoneNumber: '(888) 221-9804',
        email: 'support@safeguard.com',
      },
    },
    legalLinks: {
      privacyNotice: links.privacyNotice,
      privacyPolicy: links.privacyPolicy,
      rateDisclosure: links.rateDisclosureItem,
      termsOfService: links.termsOfService,
      rewardTerms: links.rewardsTerms,
      cardholderAgreement: links.cardholderAgreement,
      securityAgreement: links.securityAgreementItem,
      patriotAct: links.patriotAct,
      accountOpeningDisclosures: links.accountOpeningDisclosures,
      eSignDisclosure: links.eSignDisclosure,
      tcpa: links.tcpa,
    },
    links: {
      appStoreUrl: links.appStoreUrl,
      playStoreUrl: links.playStoreUrl,
    },
  }),
  [Program.HONOR]: createDefaultCashFlow({
    logo: honorLogo,
    favicon: '/honor-favicon.svg',
    theme: programThemes[Program.HONOR],
    meta: {
      title: 'Honor Mastercard',
      description: 'Honor Mastercard',
    },
    images: {
      loginBg: honorBgImage,
    },
    texts: {
      cardName: 'Honor Mastercard®',
      programName: 'Honor',
      customerSupport: {
        phoneNumber: '(888) 221-9804',
        email: 'support@honor.com',
      },
    },
    legalLinks: {
      privacyNotice: links.privacyNotice,
      privacyPolicy: links.privacyPolicy,
      rateDisclosure: links.rateDisclosureCash,
      termsOfService: links.termsOfService,
      rewardTerms: links.rewardsTerms,
      cardholderAgreement: links.cardholderAgreement,
      securityAgreement: links.securityAgreementCash,
      patriotAct: links.patriotAct,
      accountOpeningDisclosures: links.accountOpeningDisclosures,
      eSignDisclosure: links.eSignDisclosure,
      tcpa: links.tcpa,
    },
    links: {
      appStoreUrl: links.appStoreUrl,
      playStoreUrl: links.playStoreUrl,
    },
  }),
  [Program.PESTO_ITEM]: createDefaultItemFlow({
    logo: pestoLogo,
    theme: programThemes[Program.PESTO_ITEM],
    meta: {
      title: 'Pesto Mastercard',
      description: 'Pesto Mastercard',
    },
    texts: {
      cardName: 'Pesto Mastercard®',
      programName: 'Pesto',
      customerSupport: {
        phoneNumber: '(888) 221-9804',
        email: 'support@getpesto.com',
      },
    },
    layout: pestoLayout,
    legalLinks: {
      privacyNotice: links.privacyNotice,
      privacyPolicy: links.privacyPolicy,
      rateDisclosure: links.rateDisclosureItem,
      termsOfService: links.termsOfService,
      rewardTerms: links.rewardsTerms,
      cardholderAgreement: links.cardholderAgreement,
      securityAgreement: links.securityAgreementItem,
      patriotAct: links.patriotAct,
      accountOpeningDisclosures: links.accountOpeningDisclosures,
      eSignDisclosure: links.eSignDisclosure,
      tcpa: links.tcpa,
    },
    links: {
      appStoreUrl: links.appStoreUrl,
      playStoreUrl: links.playStoreUrl,
    },
  }),
  [Program.PESTO_CASH]: createDefaultCashFlow({
    logo: pestoLogo,
    theme: programThemes[Program.PESTO_CASH],
    meta: {
      title: 'Pesto Secured Mastercard',
      description: 'Pesto Secured Mastercard',
    },
    texts: {
      cardName: 'Pesto Secured Mastercard®',
      programName: 'Pesto',
      customerSupport: {
        phoneNumber: '(888) 221-9804',
        email: 'support@getpesto.com',
      },
    },
    layout: pestoLayout,
    legalLinks: {
      privacyNotice: links.privacyNotice,
      privacyPolicy: links.privacyPolicy,
      rateDisclosure: links.rateDisclosureCash,
      termsOfService: links.termsOfService,
      rewardTerms: links.rewardsTerms,
      cardholderAgreement: links.cardholderAgreement,
      securityAgreement: links.securityAgreementCash,
      patriotAct: links.patriotAct,
      accountOpeningDisclosures: links.accountOpeningDisclosures,
      eSignDisclosure: links.eSignDisclosure,
      tcpa: links.tcpa,
    },
    links: {
      appStoreUrl: links.appStoreUrl,
      playStoreUrl: links.playStoreUrl,
    },
  }),
};
