import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWarning = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 65 64"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={64} height={64} x={0.5} fill="#FDCF87" rx={32} />
    <path
      fill="currentColor"
      d="M32 36.98c-1.23 0-2.262 1.031-2.262 2.261S30.77 41.502 32 41.502c1.185 0 2.262-1.03 2.207-2.207A2.21 2.21 0 0 0 32 36.98"
    />
    <path
      fill="currentColor"
      d="M52.93 44.994c1.42-2.452 1.428-5.373.017-7.815l-14.164-24.53c-1.402-2.47-3.934-3.934-6.775-3.934-2.84 0-5.372 1.474-6.774 3.925L11.05 37.197c-1.41 2.47-1.401 5.409.028 7.86a7.74 7.74 0 0 0 6.756 3.88h28.293c2.83 0 5.372-1.474 6.801-3.943m-3.076-1.773a4.27 4.27 0 0 1-3.736 2.162H17.826a4.22 4.22 0 0 1-3.69-2.117 4.28 4.28 0 0 1-.01-4.314L28.31 14.404a4.2 4.2 0 0 1 3.7-2.144c1.546 0 2.93.805 3.699 2.153L49.88 38.96c.76 1.32.75 2.912-.027 4.26"
    />
    <path
      fill="currentColor"
      d="M31.439 21.106c-1.077.308-1.746 1.284-1.746 2.47.054.714.1 1.438.154 2.152.153 2.723.307 5.39.461 8.113.054.923.769 1.592 1.691 1.592.923 0 1.647-.714 1.692-1.646 0-.56 0-1.076.054-1.646.1-1.746.208-3.491.308-5.237.054-1.13.153-2.261.208-3.392 0-.407-.055-.769-.208-1.13a2.27 2.27 0 0 0-2.614-1.276"
    />
  </svg>
);
export default SvgWarning;
