export const Program = {
  HOMELIGHT: 'home_light',
  SAFEGUARD: 'safeguard',
  HONOR: 'honor',
  TROVY: 'trovy',
  PESTO_ITEM: 'pesto_item',
  PESTO_CASH: 'pesto_cash',
} as const;

export type Program = (typeof Program)[keyof typeof Program];
