export interface HeapCookie {
  identity: string;
  pageviewId: string;
  sessionId: string;
  userId: string;
  trackerVersion: string;
}

export const getCookies = (cookieKey: string) => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find(cookie => cookie.includes(cookieKey));
  if (cookie) {
    return cookie.split('=')[1];
  }
  return null;
};

export const parseCookies = (cookie: string | undefined | null) => {
  if (!cookie) {
    return null;
  }
  return JSON.parse(decodeURIComponent(cookie));
};

export const setCookies = (cookieKey: string, cookieValue: string, expirationDays = 365) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expirationDays);
  document.cookie = `${cookieKey}=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const removeCookie = (cookieKey: string) => {
  document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
