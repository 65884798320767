import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

interface CartStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  isOtherItemInReview: boolean;
  noItems: boolean;
  isCreditLimitTooLow: boolean;
  goToAddItem: () => void;
}

export const CartStatusModal = (props: CartStatusModalProps) => {
  const { isOpen, onClose, isCreditLimitTooLow, isOtherItemInReview, noItems, goToAddItem } = props;

  const renderModalInnerContent = () => {
    if (isOtherItemInReview) {
      return (
        <div className="flex flex-col items-center gap-4 p-8">
          <Typography variant={'headerLarge'}>We are reviewing your item</Typography>
          <Typography variant={'headerSmall'}>One of our professionals is reviewing your item.</Typography>
          <Typography variant={'body'}>
            Once we have reviewed your item, you will receive an email to continue the credit process.
          </Typography>
          <Typography variant={'body'}>Thank you!</Typography>
          <Button buttonType="secondary" className="!w-auto" onClick={onClose} text={'Go back to cart'} />
        </div>
      );
    }
    if (noItems) {
      return (
        <div className="flex flex-col items-center gap-4 p-8">
          <Typography variant={'headerLarge'}>No items in cart</Typography>
          <Typography variant={'body'}>Please add an item to proceed with your application</Typography>
          <div className={'flex flex-col gap-4 sm:flex-row'}>
            <Button buttonType="secondary" className="!w-auto" onClick={onClose} text={'Go back to cart'} />
            <Button buttonType="primary" className="!w-auto" text="Add an item" onClick={goToAddItem} />
          </div>
        </div>
      );
    }
    if (isCreditLimitTooLow) {
      return (
        <div className="flex flex-col items-center gap-4 p-8">
          <Typography variant={'headerLarge'}>Credit limit too low</Typography>
          <Typography variant={'body'}>Item value must exceed $300. Please add more items.</Typography>
          <div className={'flex flex-col gap-4 sm:flex-row'}>
            <Button buttonType="secondary" className="!w-auto" onClick={onClose} text={'Go back to cart'} />
            <Button buttonType="primary" className="!w-auto" text="Add an item" onClick={goToAddItem} />
          </div>
        </div>
      );
    }
  };

  return (
    <Modal2 className={'sm:max-w-4xl'} isOpen={isOpen} onClose={onClose}>
      {renderModalInnerContent()}
    </Modal2>
  );
};
