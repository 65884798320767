import type { CurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { ApolloProviderWrapper, type ApolloClientConfig } from '../api/Apollo';
import { QuickAppraisalStoreProvider } from '../features/QuickAppraisal/store/QuickAppraisalStoreProvider';

import { AuthProvider } from './AuthProvider/AuthProvider';
import type { AuthConfig } from './AuthProvider/AuthProvider.types';
import { ModalProvider } from './ModalProvider';
import { SessionExpiredProvider } from './SessionExpiredProvider';
import { ToastProvider } from './ToastProvider';
import { AnalyticsProvider } from './analytics/AnalyticsProvider';

interface EnvironmentVariables {
  googleMapsApiKey: string;
  pestoAnalyticsUrl: string;
  segmentApiKey: string;
}
interface HubbleSharedContextType {
  authConfig: AuthConfig;
  apolloConfig: ApolloClientConfig;
  currentUser?: CurrentUserQuery['currentUser'];
  isProd?: boolean;
  environmentVariables: EnvironmentVariables;
}

const HubbleSharedContext = createContext<HubbleSharedContextType | undefined>(undefined);

interface HubbleSharedProviderProps {
  children: ReactNode;
  authConfig: AuthConfig;
  apolloConfig: ApolloClientConfig;
  isProd?: boolean;
  currentUser?: CurrentUserQuery['currentUser'];
  quickAppraisalStorageKey?: string;
  environmentVariables: EnvironmentVariables;
}

export const HubbleSharedProvider = ({
  children,
  authConfig,
  apolloConfig,
  isProd = false,
  currentUser,
  quickAppraisalStorageKey = 'quickAppraisal',
  environmentVariables,
}: HubbleSharedProviderProps) => {
  const contextValue: HubbleSharedContextType = {
    authConfig,
    apolloConfig,
    currentUser,
    isProd,
    environmentVariables,
  };

  return (
    <HubbleSharedContext.Provider value={contextValue}>
      <AuthProvider config={authConfig}>
        <ApolloProviderWrapper config={apolloConfig} isDev={!isProd}>
          <SessionExpiredProvider>
            <AnalyticsProvider currentUser={currentUser} isProd={isProd}>
              <ModalProvider>
                <ToastProvider>
                  <QuickAppraisalStoreProvider localStorageKey={quickAppraisalStorageKey}>
                    {children}
                  </QuickAppraisalStoreProvider>
                </ToastProvider>
              </ModalProvider>
            </AnalyticsProvider>
          </SessionExpiredProvider>
        </ApolloProviderWrapper>
      </AuthProvider>
    </HubbleSharedContext.Provider>
  );
};

export const useHubbleShared = () => {
  const context = useContext(HubbleSharedContext);
  if (context === undefined) {
    throw new Error('useHubbleShared must be used within a HubbleSharedProvider');
  }
  return context;
};

export const useEnvironmentVariables = () => {
  const { environmentVariables } = useHubbleShared();
  return environmentVariables;
};
