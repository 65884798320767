import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgToggle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 36 10"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="currentColor" d="M5.5 3.75a1.25 1.25 0 1 0 0 2.5H13a1.25 1.25 0 1 0 0-2.5z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 0a5 5 0 0 0 0 10h25a5 5 0 0 0 0-10zM3 5a2.5 2.5 0 0 1 2.5-2.5h25a2.5 2.5 0 1 1 0 5h-25A2.5 2.5 0 0 1 3 5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgToggle;
