import { ApolloLink } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import type { CurrentUserQuery } from '../../__generated__/graphql/api';
import { CollateralType, CurrentUserDocument } from '../../__generated__/graphql/api';
import { useEnvironmentVariables } from '../../providers/HubbleSharedProvider';
import { initSegment } from '../../utils/scripts/segmentScript';
import { hasUserCookie, setUserCookie } from '../../utils/userCookie';
import { apolloClient } from '../Apollo';

export const useHandleApolloLogs = () => {
  const { segmentApiKey } = useEnvironmentVariables();

  return new ApolloLink((operation, forward) => {
    if (operation.operationName === 'currentUser') {
      const user = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument })?.currentUser;
      const userId = user?.id;
      const userName = user?.displayName;
      const userEmail = user?.email;
      const collateralType = user?.creditApplication?.collateralType;
      const creditApplicationId = user?.creditApplication?.id;
      const isCollateralTypeCash = collateralType === CollateralType.Cash;
      const paymentPreference = user?.creditApplication?.paymentPreference;
      const status = user?.creditApplication?.status;
      const userContext = {
        id: userId || '',
        name: userName || '',
        email: userEmail || '',
        creditApplicationId: creditApplicationId || '',
        status: status || '',
        collateralType: collateralType || '',
        ...(isCollateralTypeCash && { paymentPreference: paymentPreference || '' }),
      };

      datadogLogs.setUser(userContext);
      datadogRum.setUser(userContext);

      if (!!user && !!user.id && !hasUserCookie()) {
        setUserCookie({
          userId: user.id,
        });
      }

      if (hasUserCookie()) {
        initSegment(segmentApiKey);
      }
    }

    return forward(operation);
  });
};
