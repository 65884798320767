type StorageState = Map<string, string>;

interface StorageFunctions {
  getItem: (state: StorageState, key: string) => string | null;
  setItem: (state: StorageState, key: string, value: string) => StorageState;
  removeItem: (state: StorageState, key: string) => StorageState;
  key: (state: StorageState, index: number) => string | null;
  clear: (state: StorageState) => StorageState;
  getLength: (state: StorageState) => number;
}

const createBrowserStorage = (): StorageFunctions => {
  const getItem = (state: StorageState, key: string): string | null => {
    return state.get(key) ?? null;
  };

  const setItem = (state: StorageState, key: string, value: string): StorageState => {
    const newState = new Map(state);
    newState.set(key, value);
    return newState;
  };

  const removeItem = (state: StorageState, key: string): StorageState => {
    const newState = new Map(state);
    newState.delete(key);
    return newState;
  };

  const key = (state: StorageState, index: number): string | null => {
    const keys = Array.from(state.keys());
    return keys[index] ?? null;
  };

  const clear = (): StorageState => {
    return new Map();
  };

  const getLength = (state: StorageState): number => {
    return state.size;
  };

  return { getItem, setItem, removeItem, key, clear, getLength };
};

const browserStorageFunctions = createBrowserStorage();

let browserStorageState: StorageState = new Map();

/**
 * LocalStorage and SessionStorage SSR-safe wrappers
 * If you want to mock storage in unit tests - mock module via jest.mock
 * More info: https://jestjs.io/docs/bypassing-module-mocks
 */

function getLocalStorage(): Storage {
  if (typeof window !== 'undefined' && 'localStorage' in window) {
    return localStorage;
  }

  return {
    get length() {
      return browserStorageFunctions.getLength(browserStorageState);
    },
    getItem(key: string) {
      return browserStorageFunctions.getItem(browserStorageState, key);
    },
    setItem(key: string, value: string) {
      browserStorageState = browserStorageFunctions.setItem(browserStorageState, key, value);
    },
    removeItem(key: string) {
      browserStorageState = browserStorageFunctions.removeItem(browserStorageState, key);
    },
    key(index: number): string | null {
      return browserStorageFunctions.key(browserStorageState, index);
    },
    clear() {
      browserStorageState = browserStorageFunctions.clear(browserStorageState);
    },
  };
}

export const LocalStorage = getLocalStorage();
function getSessionStorage(): Storage {
  if (typeof window !== 'undefined' && 'sessionStorage' in window) {
    return sessionStorage;
  }

  return {
    get length() {
      return browserStorageFunctions.getLength(browserStorageState);
    },
    getItem(key: string) {
      return browserStorageFunctions.getItem(browserStorageState, key);
    },
    setItem(key: string, value: string) {
      browserStorageState = browserStorageFunctions.setItem(browserStorageState, key, value);
    },
    removeItem(key: string) {
      browserStorageState = browserStorageFunctions.removeItem(browserStorageState, key);
    },
    key(index: number): string | null {
      return browserStorageFunctions.key(browserStorageState, index);
    },
    clear() {
      browserStorageState = browserStorageFunctions.clear(browserStorageState);
    },
  };
}

export const SessionStorage = getSessionStorage();
