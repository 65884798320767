import { appRoutes, statusRoutes } from '../../../router/routes';
import type { StatusHandlers } from '../../../types/navigation.types';
import { createRouteHandlers } from '../../../utils/navigation/routeHelpers/defaultRouteNavigationHelper';

export const helocRouteHandlers: StatusHandlers = createRouteHandlers({
  OFFER_DECISION_NEEDED: () => appRoutes.personalInfo,
  OFFER_REJECTED: () => statusRoutes.offerRejected,

  CREATED: () => appRoutes.personalInfo,
  APPRAISAL_IN_PROGRESS: () => appRoutes.personalInfo,
  PREQUAL_ITEM_VALUED: () => appRoutes.personalInfo,
  FIN_INFO_NEEDED: () => appRoutes.personalInfo,
  PREQUALIFIED: () => appRoutes.personalInfo,
  OFFER_ACCEPTED: () => appRoutes.agreements,
  PREQUAL_INFO_GIVEN: () => appRoutes.personalInfo,
});
