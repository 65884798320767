import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSpeed = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#speed_svg__a)">
      <path d="M40 31.25H0v-2.5c0-11.031 8.969-20 20-20s20 8.969 20 20zM1.667 29.583h36.666v-.833c0-10.104-8.229-18.333-18.333-18.333S1.667 18.646 1.667 28.75z" />
      <path d="m27.415 20.985 1.178 1.179-5.649 5.65-1.179-1.18z" />
      <path d="M35 30.417h-1.667V28.75c0-7.354-5.979-13.333-13.333-13.333S6.667 21.396 6.667 28.75v1.667H5V28.75c0-8.27 6.73-15 15-15s15 6.73 15 15z" />
      <path d="M24.167 30.417H22.5v-.834c0-1.375-1.125-2.5-2.5-2.5a2.507 2.507 0 0 0-2.5 2.5v.834h-1.666v-.834A4.166 4.166 0 0 1 20 25.417a4.166 4.166 0 0 1 4.167 4.166zM19.167 9.583h1.666v5h-1.666zM33.378 15.031l1.178 1.179-3.535 3.535-1.179-1.178zM6.627 15.027l3.536 3.535-1.179 1.179-3.535-3.536z" />
    </g>
    <defs>
      <clipPath id="speed_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSpeed;
