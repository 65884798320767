import { createContext, type ReactNode, useContext, useRef } from 'react';
import { useStore } from 'zustand';

import { createQuickAppraisalStore, type QuickAppraisalStore } from './quickAppraisalStore';

export type QuickAppraisalStoreApi = ReturnType<typeof createQuickAppraisalStore>;

export const QuickAppraisalStoreContext = createContext<QuickAppraisalStoreApi | undefined>(undefined);

export interface QuickAppraisalStoreProviderProps {
  children: ReactNode;
  localStorageKey: string;
}

export const QuickAppraisalStoreProvider = ({ children, localStorageKey }: QuickAppraisalStoreProviderProps) => {
  const storeRef = useRef<QuickAppraisalStoreApi>();
  if (!storeRef.current) {
    storeRef.current = createQuickAppraisalStore(localStorageKey);
  }
  return <QuickAppraisalStoreContext.Provider value={storeRef.current}>{children}</QuickAppraisalStoreContext.Provider>;
};

export const useQuickAppraisalStoreContext = <T,>(selector: (store: QuickAppraisalStore) => T): T => {
  const context = useContext(QuickAppraisalStoreContext);

  if (!context) {
    throw new Error('useQuickAppraisalStore must be used within a QuickAppraisalStoreProvider');
  }
  return useStore(context, selector);
};

export const useQuickAppraisalStore = () => {
  return useQuickAppraisalStoreContext((state: QuickAppraisalStore) => state);
};
