import { LocalStorage } from '@pesto/hubble-shared/utils/core/BrowserStorage';

import { ADMIN_DEBUGGER_LS_KEY } from '../../constants/shared-constants';

import { parseURL } from './utmParamsHelper';

export const adminDebuggerUrlHelper = () => {
  const urlParams = window && parseURL(window.location.href)?.searchParams;

  // admin debugger with a secret key
  const adminDebugger = urlParams?.get('ISolemnlySwearThatIAmUpToNoGood');

  if (adminDebugger) {
    LocalStorage.setItem(ADMIN_DEBUGGER_LS_KEY, adminDebugger);
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location.reload();
  }
};
