import { CreditApplicationStatus } from '@pesto/hubble-shared/__generated__/graphql/api';

import { appRoutes, statusRoutes } from '../../../router/routes';
import type { StatusHandlers } from '../../../types/navigation.types';

/**
 * This is the default route navigation helper. It is used to navigate to the correct route based on the status.
 * It is used in the case where the status is not found in the program config.
 */
const createDefaultRouteHandlers = (): Partial<StatusHandlers> => ({
  COMPLETE: () => appRoutes.congratulations,
  NOT_PREQUALIFIED: () => statusRoutes.notPrequalified,
  MANUAL_REVIEW: () => statusRoutes.manualReview,
  CREDIT_DENIED: () => statusRoutes.manualReview,
  OFFER_REJECTED: () => statusRoutes.offerRejected,
  ITEM_UNDER_REVIEW: () => statusRoutes.inReview,
  INVALID_STATE: () => statusRoutes.manualReview,
  CREDIT_FREEZE: () => statusRoutes.creditFreeze,
  CANCELED_HARD: () => statusRoutes.manualReview,
  CANCELED_SOFT: () => statusRoutes.manualReview,
  CANCELED_DUPE: () => statusRoutes.loginToYourAccount,
  VERIFY_DOC: () => statusRoutes.manualReview,
  VERIFY_PAYMENT: () => appRoutes.verifyPayment,
});

export const createRouteHandlers = (customHandlers: Partial<StatusHandlers>): StatusHandlers => {
  const defaultHandlers = createDefaultRouteHandlers();

  return Object.values(CreditApplicationStatus).reduce(
    (acc, status) => ({
      ...acc,
      [status]: customHandlers[status] ?? defaultHandlers[status] ?? (() => statusRoutes.manualReview),
    }),
    {} as StatusHandlers,
  );
};
