import { LocalStorage, SessionStorage } from '@pesto/hubble-shared/utils/core/BrowserStorage';

export const isProtectedRoutesDisabled = SessionStorage.getItem('disableProtectedRoutesForTheSession') === 'true';

export const isFeatureFlagEnabled = (key: string): boolean => {
  const value = LocalStorage.getItem(key);
  return value === 'true';
};

export const setFeatureFlag = (key: string, value: boolean) => {
  LocalStorage.setItem(key, String(value));
  window.location.reload();
};
