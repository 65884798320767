import { BlackDiamondModal } from 'src/components/features/GlobalModals/modals/BlackDiamondModal';
import { CartStatusModal } from 'src/components/features/GlobalModals/modals/CartStatusModal';
import { SsnDuplicateModal } from 'src/components/features/GlobalModals/modals/SsnDuplicateModal';
import { FlowType } from 'src/config/programConfig';
import { getCurrentProgramConfig } from 'src/config/programHelper';
import { useCreditApp } from 'src/flows/item/hooks/useCreditApp';
import { useGoRoutes } from 'src/hooks/useGoRoutes';
import { useModalStore } from 'src/store/modalStore';

import { OnboardingModal } from './modals/OnboardingModal';

export const GlobalModals = () => {
  const { goToAddItem, isCreditLimitTooLow, noItems, isOtherItemInReview } = useCreditApp();
  const {
    itemFlow: { gotoVerifyIncome },
  } = useGoRoutes();
  const { flowType } = getCurrentProgramConfig();

  const { cartStatus, blackDiamond, ssnDuplicate, onboarding } = useModalStore(state => state.modals);

  const handleDuplicateSsnButtonClick = () => {
    if (flowType === FlowType.ITEM) {
      gotoVerifyIncome();
    }
    ssnDuplicate.setIsOpen(false);
  };

  return (
    <>
      <SsnDuplicateModal
        modalError={ssnDuplicate.isOpen}
        setModalError={ssnDuplicate.setIsOpen}
        modalButtonText={'Verify income info'}
        handleModalButtonClick={handleDuplicateSsnButtonClick}
      />
      <CartStatusModal
        isOpen={cartStatus.isOpen}
        onClose={() => cartStatus.setIsOpen(false)}
        isCreditLimitTooLow={isCreditLimitTooLow}
        noItems={noItems}
        isOtherItemInReview={isOtherItemInReview}
        goToAddItem={goToAddItem}
      />
      <BlackDiamondModal isModalOpen={blackDiamond.isOpen} setIsModalOpen={blackDiamond.setIsOpen} />
      <OnboardingModal
        isOpen={onboarding.isOpen}
        onClose={() => onboarding.setIsOpen(false)}
        onContinue={() => onboarding.setIsOpen(false)}
      />
    </>
  );
};
