import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import NewDiamondUserBlocked from '@pesto/hubble-shared/features/QuickAppraisal/screens/Gemstone/NewDiamondUserBlocked';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';

interface BlackDiamondModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export const BlackDiamondModal = (props: BlackDiamondModalProps) => {
  const { isModalOpen, setIsModalOpen } = props;
  const {
    itemFlow: { gotoQuickAppraisal },
  } = useGoRoutes();

  return (
    <Modal2 isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <NewDiamondUserBlocked onNext={() => {}} onBack={() => gotoQuickAppraisal()} />
    </Modal2>
  );
};
