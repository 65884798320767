import { datadogRum } from '@datadog/browser-rum';

import { CONSENT_BANNER_SHOWN_COOKIE_KEY, CONSENT_SETTING_COOKIE_KEY } from '../constants/local-storage-keys';

import { getCookies, parseCookies, setCookies } from './cookies';
import { initializeTapfiliate } from './scripts/tapfilliateScript';
import { setUserCookieFromApolloCache } from './userCookie';

declare global {
  interface Window {
    dataLayer?: {
      push: (...data: any) => void;
    };
  }
}

const googleConsent = [
  'ad_storage',
  'analytics_storage',
  'personalization_storage',
  'functionality_storage',
  'security_storage',
  'ad_user_data',
  'ad_personalization',
];
export type GoogleConsentObject = Record<(typeof googleConsent)[number], ConsentMode>;
export type ConsentGroup = 'essential' | 'functional' | 'performance';
export type ConsentMode = 'granted' | 'denied';
export type ConsentData = Record<ConsentGroup, boolean>;

export const gtmConsentGroups: Record<ConsentGroup, string[]> = {
  essential: [],
  functional: [],
  performance: [
    'analytics_storage',
    'security_storage',
    'functionality_storage',
    'ad_storage',
    'personalization_storage',
    'ad_user_data',
    'ad_personalization',
  ],
};

const defaultConsent: ConsentData = {
  essential: true,
  functional: false,
  performance: false,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(...args: any[]) {
  window.dataLayer = window.dataLayer || ([] as any);
  // IMPORTANT: We need to use the arguments object here because the gtag function is called with a variable number of arguments
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments);
}

const getGoogleConsentData = (data: ConsentData): GoogleConsentObject => {
  return googleConsent.reduce((acc, key) => {
    const group = Object.keys(gtmConsentGroups).find(group => gtmConsentGroups[group as ConsentGroup].includes(key));
    return { ...acc, [key]: data[group as ConsentGroup] ? 'granted' : 'denied' };
  }, {} as GoogleConsentObject);
};

export const getGoogleConsent = () => {
  const cookieConsent = getCookies(CONSENT_SETTING_COOKIE_KEY);
  const parsedCookieConsent = parseCookies(cookieConsent);

  if (cookieConsent === null) {
    gtag('consent', 'default', getGoogleConsentData(defaultConsent));
  } else {
    gtag('consent', 'default', getGoogleConsentData(parsedCookieConsent));
  }
};

const setConsentInCookies = (data: any) => {
  setCookies(CONSENT_SETTING_COOKIE_KEY, JSON.stringify(data));
};

export const setGoogleConsent = (data: ConsentData) => {
  const googleData: GoogleConsentObject = getGoogleConsentData(data);
  gtag('consent', 'update', googleData);
};

const setDataDogConsent = (data: ConsentData) => {
  datadogRum.setTrackingConsent(data.functional ? 'granted' : 'not-granted');
};

const reInitializeTapfiliate = (data: ConsentData) => {
  if (data.functional) {
    initializeTapfiliate();
  }
};

const setUserCookie = (userData: ConsentData) => {
  if (userData.performance) {
    setUserCookieFromApolloCache();
  }
};

export const setConsent = (data: ConsentData) => {
  setGoogleConsent(data);
  setDataDogConsent(data);
  setConsentInCookies(data);
  reInitializeTapfiliate(data);
  setUserCookie(data);
};

export const setConsentBannerShown = () => {
  setCookies(CONSENT_BANNER_SHOWN_COOKIE_KEY, JSON.stringify(new Date().toISOString()));
};

export const consentBannerShownHasBeenShown = (): boolean => {
  const cookieConsent = getCookies(CONSENT_BANNER_SHOWN_COOKIE_KEY);
  const parsedCookieConsent = parseCookies(cookieConsent);
  return parsedCookieConsent !== null;
};

export const getUserConsent = (): ConsentData => {
  const cookieConsent = getCookies(CONSENT_SETTING_COOKIE_KEY);
  const parsedCookieConsent = parseCookies(cookieConsent);

  if (cookieConsent === null) {
    return defaultConsent;
  } else {
    return parsedCookieConsent;
  }
};

export const setConsentAllGranted = () => {
  setConsent(Object.keys(gtmConsentGroups).reduce((acc, key) => ({ ...acc, [key]: true }), {} as ConsentData));
};

export const setConsentOnPageChange = () => {
  const cookieConsent = getCookies(CONSENT_SETTING_COOKIE_KEY);
  if (cookieConsent) return;

  if (!consentBannerShownHasBeenShown()) return;

  const cookieBanner = document.querySelector('#cookie-banner');
  if (cookieBanner) cookieBanner.remove();

  return setConsentAllGranted();
};
