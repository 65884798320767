import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import type { ConsentData, ConsentGroup } from '@pesto/hubble-shared/utils/consentHelper';
import { useState } from 'react';

interface CookiesSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: ConsentData) => void;
}

interface CookiesSettings {
  name: string;
  description: string;
  isOn: boolean;
  editable: boolean;
  group: ConsentGroup;
}

const cookiesSettings: CookiesSettings[] = [
  {
    name: 'Essential',
    description: 'Required to enable basic website functionality. You may not disable essential cookies.',
    isOn: true,
    editable: false,
    group: 'essential',
  },
  {
    name: 'Performance',
    description:
      'These cookies track how you use our website during your visit. Typically, this information is anonymous and aggregated, with information tracked across all site users. They help us understand visitor usage patterns, identify and diagnose problems or errors our users may encounter, and make better strategic decisions in improving our audience’s overall website experience.  They do not collect personal information about you.',
    isOn: true,
    editable: true,
    group: 'performance',
  },
  {
    name: 'Functionality',
    description:
      'These cookies are used to collect information about your device and any settings you may configure on our website (like language and time zone settings).  With this information, we can provide you with customized, enhanced, or optimized content and services.',
    isOn: true,
    editable: true,
    group: 'functional',
  },
];

const groups: ConsentGroup[] = ['essential', 'performance', 'functional'];

const defaultConsent: ConsentData = groups.reduce((acc, group) => ({ ...acc, [group]: true }), {} as ConsentData);

export const CookiesSettingsModal = (props: CookiesSettingsModalProps) => {
  const { isOpen, onClose, onSave } = props;
  const [cookies, setCookies] = useState<ConsentData>(defaultConsent);
  const handleToggle = (group: ConsentGroup) => {
    setCookies(prevState => ({
      ...prevState,
      [group]: !prevState[group],
    }));
  };

  const handleSave = () => {
    onSave(cookies);
    onClose();
  };

  const handleCancel = () => {
    setCookies(defaultConsent);
    handleSave();
  };

  return (
    <Modal2 isOpen={isOpen} onClose={onClose} className={'!max-w-2xl'}>
      <Typography variant={'headerSmall'}>Cookie settings</Typography>
      <Typography variant={'body'} className="text-gray-900">
        You can customize your cookie settings below:
      </Typography>
      <div className={'flex max-h-[300px] overflow-auto'}>
        <div className="top-[50%] flex h-auto flex-col justify-start gap-4 p-6 align-top">
          <div className="flex flex-col gap-4">
            {cookiesSettings.map((cookie, index) => (
              <div key={index} className="flex items-center justify-between gap-4 border-b border-gray-200 py-4">
                <div>
                  <Typography variant={'bodyXl'}>{cookie.name}</Typography>
                  <Typography variant={'bodyXl'} className={'text-gray-500'}>
                    {cookie.description}
                  </Typography>
                </div>
                {cookie.editable ? (
                  <button
                    className={`${
                      cookie.isOn ? 'bg-gray-100' : 'bg-gray-200'
                    } rounded-lg px-4 py-2 text-sm font-semibold text-gray-900`}
                    onClick={() => handleToggle(cookie.group)}
                  >
                    {cookies[cookie.group as keyof typeof cookies] ? 'On' : 'Off'}
                  </button>
                ) : (
                  <span className="text-gray-500">On</span>
                )}
              </div>
            ))}
          </div>
          <Typography variant={'bodySmall'} className="text-gray-400">
            Canceling will not save your changes and will keep the default recommended settings.
          </Typography>
        </div>
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          buttonType={'secondary'}
          className="rounded-lg border border-gray-300 px-4 py-2 text-sm font-semibold text-gray-900"
          onClick={handleCancel}
          text={'Cancel'}
        />
        <Button
          buttonType={'primary'}
          className="ml-2 rounded-lg px-4 py-2 text-sm font-semibold text-white"
          onClick={handleSave}
          text={'Save'}
        />
      </div>
    </Modal2>
  );
};
