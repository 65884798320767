import React from 'react';

import { Typography } from '../../../../components/UI/Typography';
import type { ScreenProps } from '../../components/ScreenConstructor/types';

const NewDiamondUserBlocked: React.FC<ScreenProps> = ({ onBack }) => {
  return (
    <div className="py-4">
      <Typography variant="bodyLarge">
        Oh no! At this time, your item can't <br /> be run through our quick appraisal <br /> tool for a credit estimate
      </Typography>
      {/* TODO: FIX SHARED */}
      {/* <UserBlockedIcon className="mx-auto h-auto pt-6" alt="user blocked in new diamond flow" /> */}

      <Typography className="pt-6" variant="body">
        This may be because most of your item's <br /> value may come from a component <br /> we do not currently
        accept—such <br /> as black or colored diamonds,
        <br /> moissanite, morganite, cubic zirconium, <br /> or another material.
      </Typography>

      <Typography variant="body" className="pt-6 font-bold">
        Want to try another item?
      </Typography>

      <div className="mt-4">
        <a
          href={''}
          className="text-action cursor-pointer font-bold underline hover:no-underline"
          onClick={e => {
            e.preventDefault();
            onBack();
          }}
        >
          Add one here
        </a>
      </div>
    </div>
  );
};

export default NewDiamondUserBlocked;
