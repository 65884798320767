import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import SvgCloseWhite from '@pesto/hubble-shared/icons/components/CloseWhite';
import clsx from 'clsx';
import { Fragment } from 'react';

interface DebuggerDrawerProps {
  className?: string;
  open: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactNode;
}

export const DebuggerDrawer = (props: DebuggerDrawerProps) => {
  const { className, open, onClose, children } = props;

  const rootStyle = clsx(className, 'relative');

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={rootStyle} onClose={() => onClose(false)}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                          Admin Debugger
                        </DialogTitle>
                        <div className="bg-basic ml-3 flex h-7 items-center rounded p-2 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2">
                          <button
                            type="button"
                            className="aspect-square rounded-md text-gray-400 hover:text-gray-500"
                            onClick={() => onClose(false)}
                          >
                            <span className="sr-only">Close panel</span>

                            <SvgCloseWhite className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {children}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
