import { appRoutes } from '../../../router/routes';
import type { StatusHandlers } from '../../../types/navigation.types';
import { createRouteHandlers } from '../../../utils/navigation/routeHelpers/defaultRouteNavigationHelper';

export const cashRouteHandlers: StatusHandlers = createRouteHandlers({
  CREATED: () => appRoutes.creditLine,
  APPRAISAL_IN_PROGRESS: () => appRoutes.personalInfo,
  PREQUAL_ITEM_VALUED: () => appRoutes.personalInfo,
  FIN_INFO_NEEDED: () => appRoutes.personalInfo,
  PREQUALIFIED: () => appRoutes.payment,
  OFFER_DECISION_NEEDED: () => appRoutes.agreements,
  OFFER_ACCEPTED: () => appRoutes.congratulations,

  PREQUAL_INFO_GIVEN: () => appRoutes.payment,
});
