import { CreditApplicationStatus } from '@pesto/hubble-shared/__generated__/graphql/api';
import { useCallback, useMemo } from 'react';
import { useNavigate, type NavigateOptions } from 'react-router-dom';

import { appRoutes, cashPaymentRoutes, loginRoutes, offerRoutes, routes, statusRoutes } from '../router/routes';
import { getURLPathForStatus } from '../utils/navigation/navigationHelper';

interface Options extends NavigateOptions {
  query?: Record<string, string>;
}

export const useGoRoutes = () => {
  const gotoRoute = useNavigate();
  const goBack = useCallback(() => gotoRoute(-1), [gotoRoute]);

  const searchParams = (query: Record<string, string>) => {
    return `?${new URLSearchParams(query).toString()}`;
  };

  const gotoRouteWithSearchParams = useCallback(
    (route: string, options?: Options) => {
      return gotoRoute(route + (options?.query ? searchParams(options.query) : ''), options);
    },
    [gotoRoute],
  );

  return useMemo(() => {
    return {
      navigate: (route: string, options?: Options) => gotoRouteWithSearchParams(route, options),

      goBack: () => {
        goBack();
      },

      gotoLogin: () => {
        gotoRoute(routes.login);
      },

      gotoSignUp: (options?: Options) => gotoRouteWithSearchParams(loginRoutes.signUp, options),

      gotoHome: () => {
        gotoRoute(routes.home);
      },
      gotoApp: () => {
        gotoRoute(routes.app);
      },
      gotoPersonalInfo: () => {
        gotoRoute(appRoutes.personalInfo);
      },

      gotoStatus: (status: CreditApplicationStatus | null | undefined) => {
        gotoRoute(getURLPathForStatus(status || CreditApplicationStatus.InvalidState));
      },

      gotoApplicationQuickAppraisal: (options?: NavigateOptions) => {
        gotoRoute(appRoutes.quickAppraisal, options);
      },
      gotoPersonalAndAddressInfo: () => {
        gotoRoute(appRoutes.personalInfo);
      },
      gotoPreQualified: () => {
        gotoRoute(statusRoutes.manualReview);
      },

      helocFlow: {
        gotoPersonalInfo: () => {
          gotoRoute(appRoutes.personalInfo);
        },
        gotoOffer: () => {
          gotoRoute(appRoutes.offer);
        },
        gotoCancelOffer: () => {
          gotoRoute(appRoutes.cancelOffer);
        },
        gotoFinalAgreements: () => {
          gotoRoute(appRoutes.agreements);
        },
        gotoCongratulations: () => {
          gotoRoute(appRoutes.congratulations);
        },
      },

      itemFlow: {
        gotoOffer: (options?: NavigateOptions) => gotoRouteWithSearchParams(offerRoutes.root, options),
        gotoRejectOffer: (options?: NavigateOptions) => gotoRouteWithSearchParams(offerRoutes.rejectOffer, options),
        gotoVerifyInfo: (options?: NavigateOptions) => gotoRouteWithSearchParams(offerRoutes.verifyInfo, options),
        gotoVerifyIncome: (options?: NavigateOptions) => gotoRouteWithSearchParams(offerRoutes.verifyIncome, options),
        gotoFinalAgreements: (options?: NavigateOptions) =>
          gotoRouteWithSearchParams(offerRoutes.finalAgreements, options),
        gotoPersonalInfo: (options?: NavigateOptions) => gotoRouteWithSearchParams(appRoutes.personalInfo, options),
        gotoQuickAppraisal: (options?: NavigateOptions) => gotoRouteWithSearchParams(appRoutes.quickAppraisal, options),
        gotoItemInReview: (options?: NavigateOptions) => gotoRouteWithSearchParams(appRoutes.itemInReview, options),
        gotoItemCart: (options?: NavigateOptions) => gotoRouteWithSearchParams(appRoutes.itemsCart, options),
        gotoPreQualified: (options?: NavigateOptions) => gotoRouteWithSearchParams(appRoutes.preQualified, options),
        gotoItemCartWithOptions: (options?: NavigateOptions) => gotoRouteWithSearchParams(appRoutes.itemsCart, options),
      },

      cashFlow: {
        gotoCashSecured: () => gotoRoute(appRoutes.home),
        gotoCreditLine: () => gotoRoute(appRoutes.creditLine),
        gotoCashPersonalInfo: () => gotoRoute(appRoutes.personalInfo),
        gotoFundOverTime: () => gotoRoute(cashPaymentRoutes.fundOverTime),
        gotoPayment: () => gotoRoute(appRoutes.payment),
        gotoPaymentWithOptions: (options?: NavigateOptions) => gotoRoute(appRoutes.payment, { ...options }),
        gotoFundNow: () => gotoRoute(cashPaymentRoutes.fundNow),
        gotoAgreements: () => gotoRoute(appRoutes.agreements),
        gotoCongratulations: () => gotoRoute(appRoutes.congratulations),
      },
    };
  }, [goBack, gotoRoute, gotoRouteWithSearchParams]);
};
