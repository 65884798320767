import type { UpdateIssues } from '@pesto/hubble-shared/__generated__/graphql/api';

const updateIssuesTrueArray = (updateIssues: UpdateIssues) => {
  return Object.entries(updateIssues || {}).filter(([, value]) => value === true);
};
export const hasUpdateIssues = (updateIssues: UpdateIssues | undefined | null) => {
  if (!updateIssues) return false;
  const arr = updateIssuesTrueArray(updateIssues);
  return arr.length > 0;
};

export const updateIssueKey = (updateIssues: UpdateIssues | undefined | null) => {
  if (!updateIssues) return '';
  const flatArray = updateIssuesTrueArray(updateIssues).flat();
  return flatArray[0] || '';
};
