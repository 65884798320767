import { lazy } from 'react';

import merge from 'lodash/merge';

import { cashRouteHandlers } from '../flows/cash/navigation/cashRouteHelper';
import { helocRouteHandlers } from '../flows/heloc/navigation/helocRouteHelper';
import { itemRouteHandlers } from '../flows/item/navigation/itemRouteHelper';
import defaultLogo from '../programs/Pesto/assets/pesto-logo.svg';

import type { BaseProgramConfig, CashFlowConfig, HelocFlowConfig, ItemFlowConfig } from './program.types';
import { FlowType } from './programConfig';

const CashRoutesLazy = lazy(() => import('../flows/cash/CashRoutes').then(module => ({ default: module.CashRoutes })));
const HelocRoutesLazy = lazy(() =>
  import('../flows/heloc/HelocRoutes').then(module => ({ default: module.HelocRoutes })),
);
const ItemRoutesLazy = lazy(() => import('../flows/item/ItemRoutes').then(module => ({ default: module.ItemRoutes })));

const defaultConfig = {
  logo: defaultLogo,
  favicon: '/pesto-favicon.png',
  meta: {
    title: 'Default Application',
    description: 'Default Application',
  },
  texts: {
    cardName: 'Default Card',
    customerSupport: {
      phoneNumber: '(888) 221-9804',
      email: 'support@homelight.com',
    },
  },
  images: {},
};

const createDefaultFlow = <T extends BaseProgramConfig>(flowSpecificConfig: Partial<T>, config: Partial<T>): T => {
  const defaultFlowConfig = {
    ...defaultConfig,
    ...flowSpecificConfig,
  } as T;

  return merge({}, defaultFlowConfig, config);
};

export const createDefaultItemFlow = (config: Partial<ItemFlowConfig>): ItemFlowConfig =>
  createDefaultFlow(
    {
      flowType: FlowType.ITEM,
      RouteComponent: ItemRoutesLazy,
      statusHandlers: itemRouteHandlers,
    },
    config,
  );

export const createDefaultCashFlow = (config: Partial<CashFlowConfig>): CashFlowConfig =>
  createDefaultFlow(
    {
      flowType: FlowType.CASH,
      RouteComponent: CashRoutesLazy,
      statusHandlers: cashRouteHandlers,
    },
    config,
  );

export const createDefaultHelocFlow = (config: Partial<HelocFlowConfig>): HelocFlowConfig =>
  createDefaultFlow(
    {
      flowType: FlowType.HELOC,
      RouteComponent: HelocRoutesLazy,
      statusHandlers: helocRouteHandlers,
      layout: {
        header: {
          theme: 'light',
          removeLoginButton: true,
        },
        loginPage: {
          bgColor: '#ffffff',
        },
      },
    },
    config,
  );
