import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import { useEffect, useMemo, type ReactNode } from 'react';

import { getCurrentProgramConfig, isCashFlow, isItemFlow } from '../config/programHelper';
import { useGoRoutes } from '../hooks/useGoRoutes';
import { useUser } from '../hooks/useUser';
import { routes } from '../router/routes';

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useUser();
  const { navigate } = useGoRoutes();

  const isItemOrCashFlow = isItemFlow(getCurrentProgramConfig()) || isCashFlow(getCurrentProgramConfig());

  const accessibleRoutes = useMemo(() => {
    return [routes.quickAppraisal, routes.loginCallback];
  }, []);

  const isAccessibleRoute = accessibleRoutes.includes(window.location.pathname);

  useEffect(() => {
    if (!isAuthenticated() && !isItemOrCashFlow && !isAccessibleRoute) {
      return navigate(routes.login);
    }
  }, [isAuthenticated, user, navigate, isItemOrCashFlow, isAccessibleRoute]);

  return <>{children}</>;
};
