import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHelpSupport = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 65 64"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={64} height={64} x={0.5} fill="#87D5AF" rx={32} />
    <path
      fill="currentColor"
      d="M52.25 38.805c0-5.612-3.781-10.514-9.113-11.992C42.8 19.002 36.343 12.75 28.45 12.75c-8.106 0-14.701 6.595-14.701 14.7 0 2.643.703 5.215 2.039 7.473l-1.985 7.174 7.174-1.984a14.65 14.65 0 0 0 6.835 2.023c1.477 5.332 6.38 9.114 11.992 9.114a12.4 12.4 0 0 0 6.332-1.73l6.059 1.676-1.676-6.059a12.4 12.4 0 0 0 1.73-6.332m-30.916-1.131-4.294 1.188 1.188-4.295-.271-.424a12.4 12.4 0 0 1-1.951-6.692c0-6.863 5.583-12.445 12.445-12.445s12.444 5.583 12.444 12.445-5.582 12.444-12.444 12.444c-2.382 0-4.696-.674-6.693-1.95zM48.961 47.96l-3.187-.882-.426.277a10.15 10.15 0 0 1-5.543 1.638c-4.41 0-8.286-2.852-9.66-6.941 6.755-.779 12.13-6.153 12.908-12.908a10.215 10.215 0 0 1 6.941 9.66 10.15 10.15 0 0 1-1.638 5.543l-.277.425z"
    />
    <path
      fill="currentColor"
      d="M27.323 33.128h2.256v2.256h-2.256zM30.707 24.03c0 .64-.261 1.231-.735 1.665l-2.65 2.424v2.753h2.257v-1.76l1.916-1.753a4.52 4.52 0 0 0 1.467-3.33 4.517 4.517 0 0 0-4.511-4.511 4.517 4.517 0 0 0-4.512 4.511h2.256a2.26 2.26 0 0 1 2.256-2.256 2.26 2.26 0 0 1 2.255 2.256"
    />
  </svg>
);
export default SvgHelpSupport;
