import { onError } from '@apollo/client/link/error';
import { CurrentUserDocument, type CurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { apolloClient } from '@pesto/hubble-shared/api/Apollo';

import { useSessionExpiredContext } from '../../providers/SessionExpiredProvider';

export const useHandleApolloErrors = () => {
  const { setErrorStatus, skipError, handleIsOpenModal } = useSessionExpiredContext();
  return onError(({ graphQLErrors, networkError, operation }) => {
    const userId = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument })?.currentUser?.id;

    const ifUserIsLoggedIn = userId ? `userID: ${userId}` : '';
    if (!ifUserIsLoggedIn) return;
    if (graphQLErrors) {
      if (!graphQLErrors) {
        return console.error(
          `[GraphQL error]: ${ifUserIsLoggedIn}, operation - ${operation.operationName} -> Message: `,
          graphQLErrors,
        );
      }
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: ${ifUserIsLoggedIn}, operation - ${
            operation.operationName
          } -> Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`,
        );

        // Hack to allow users close the modal
        if (skipError) return;

        if (message.includes('Unauthorized')) {
          setErrorStatus('Unauthorized');
          handleIsOpenModal(true);
        }
      });
    }
    if (networkError)
      console.error(`[Network error]: ${ifUserIsLoggedIn} , operation - ${operation.operationName} -> ${networkError}`);
  });
};
