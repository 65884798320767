/**
 * Z-index constants
 */
export const Z_INDEX = {
  HEADER: 1000,
  MODAL: 1100,
  DROPDOWN: 1200,
} as const;

/**
 *
 * File size constants in bytes
 */
export const FILE_SIZE = {
  BYTE: 1,
  KB: 1024,
  MB: 1024 * 1024,
} as const;

/**
 * Common multipliers used across different domains
 */
export const MULTIPLIERS = {
  MONEY: 100,
  CARAT: 100,
  GRAM: 10,
} as const;

/**
 * Upload limits configuration
 */
export const UPLOAD_LIMITS = {
  MAX_FILE_SIZE: 6 * FILE_SIZE.MB,
  MAX_FILES: 10,
  ALLOWED_TYPES: ['image/jpeg', 'image/png', 'image/webp'] as const,
  DESCRIPTION_MAX_LENGTH: 255,
} as const;

/**
 * Payment and credit related monetary constants (in USD)
 */
export const MONETARY_LIMITS = {
  MINIMUM_PAYMENT_AMOUNT: 20,
  FEES: {
    FUND_OVER_TIME: 20,
    SPLIT_PAYMENT: 20,
    MINIMUM_PAYMENT: 20, // Equal to SPLIT_PAYMENT_FEE
  },
  CREDIT: {
    MIN_AMOUNT: {
      NON_CARDHOLDER: 200,
      CARDHOLDER: 100,
    },
    MAX_AMOUNT: 2000,
    LINE: {
      DEFAULT: 2000,
      INCREASE_LIMIT: 1999, // MAX_CREDIT_LINE - 1
    },
  },
} as const;

/**
 * Form validation constants
 */
export const FORM_LIMITS = {
  MAX_STRING_LENGTH: 255,
  VERIFICATION_CODE_LENGTH: 6,
  SSN_LENGTH: 9,
  PHONE_MIN_LENGTH: 11,
  MAX_LENGTH: {
    NAME: 50,
    EMAIL: 255,
    ADDRESS: 35,
    CITY: 100,
    STATE: 35,
    ZIP: 5,
    PHONE: 15,
  },
  MIN_LENGTH: {
    PASSWORD: 8,
    NAME: 2,
  },
  MIN_AGE: 18,
} as const;

/**
 * API and network configuration
 */
export const API_CONFIG = {
  RETRY: {
    MAX_ATTEMPTS: 5,
    INITIAL_DELAY_MS: 300,
    MAX_DELAY_MS: 10000,
    STATUS_CODES: [500, 502, 503, 504] as const,
  },
} as const;

/**
 * UI related constants
 */
export const UI_CONFIG = {
  TOAST: {
    AUTO_CLOSE_MS: 2500,
    MAX_COUNT: 2,
  },
} as const;

/**
 * Credit card validation constants
 */
export const CREDIT_CARD = {
  LENGTH: {
    DEFAULT: 16,
    CVC: {
      DEFAULT: 3,
      AMEX: 4,
    },
  },
} as const;

/**
 * Create type definitions for the constants
 */
export type AllowedFileType = (typeof UPLOAD_LIMITS.ALLOWED_TYPES)[number];
export type RetryableStatusCode = (typeof API_CONFIG.RETRY.STATUS_CODES)[number];
