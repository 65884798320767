import type { CreditApplicationStatus, User } from '@pesto/hubble-shared/__generated__/graphql/api';
import isEmpty from 'lodash.isempty';

import { appRoutes, statusRoutes } from '../../../router/routes';
import { createRouteHandlers } from '../../../utils/navigation/routeHelpers/defaultRouteNavigationHelper';

export type StatusItemHandlers = Record<CreditApplicationStatus, (user?: User | null) => string>;

export const itemRouteHandlers: StatusItemHandlers = createRouteHandlers({
  ITEM_UNDER_REVIEW: user => {
    const billingAddress = user?.billingAddress;
    const hasEnteredBillingAddress = !isEmpty(billingAddress);

    return hasEnteredBillingAddress ? appRoutes.itemInReview : appRoutes.itemsCart;
  },
  PREQUAL_ITEM_VALUED: () => appRoutes.itemsCart,
  FIN_INFO_NEEDED: () => appRoutes.personalInfo,
  APPRAISAL_IN_PROGRESS: () => statusRoutes.manualReview,

  PREQUAL_INFO_GIVEN: () => appRoutes.preQualified,

  OFFER_DECISION_NEEDED: () => appRoutes.offer,
  OFFER_ACCEPTED: () => appRoutes.congratulations,
  OFFER_REJECTED: () => statusRoutes.offerRejected,
  CREATED: () => appRoutes.itemsCart,

  // it was remapped to manualReview after schema update while we removed shipping
  PREQUALIFIED: () => statusRoutes.manualReview,
  SHIPPED: () => statusRoutes.manualReview,
  DELIVERED: () => statusRoutes.manualReview,
});
