import { create } from 'zustand';

export const MODAL_NAMES = ['cartStatus', 'blackDiamond', 'ssnDuplicate', 'onboarding'] as const;

type ModalName = (typeof MODAL_NAMES)[number];

interface BaseModalState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

type ModalState = BaseModalState;

type ModalStateUpdate<T extends ModalState> = Omit<Partial<T>, keyof BaseModalState>;

interface ModalStore {
  modals: Record<ModalName, ModalState>;
  openModal: <T extends ModalState>(name: ModalName, data?: ModalStateUpdate<T>) => void;
  closeModal: (name: ModalName) => void;
  closeAll: () => void;
  updateModal: <T extends ModalState>(name: ModalName, data: ModalStateUpdate<T>) => void;
}

const createModalState = (name: ModalName, set: any): BaseModalState => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) =>
    set((state: ModalStore) => ({
      modals: updateModalState(state.modals, name, { isOpen }),
    })),
});

const updateModalState = <T extends ModalState>(
  state: Record<ModalName, ModalState>,
  name: ModalName,
  updates: Partial<T>,
): Record<ModalName, ModalState> => ({
  ...state,
  [name]: {
    ...state[name],
    ...updates,
  },
});

export const useModalStore = create<ModalStore>(set => {
  const initializeModals = () =>
    MODAL_NAMES.reduce(
      (acc, name) => ({
        ...acc,
        [name]: {
          ...createModalState(name as ModalName, set),
        },
      }),
      {} as Record<ModalName, ModalState>,
    );

  return {
    modals: initializeModals(),
    openModal: (name, data) =>
      set(state => ({
        modals: updateModalState(state.modals, name, { ...data, isOpen: true }),
      })),
    closeModal: name =>
      set(state => ({
        modals: updateModalState(state.modals, name, { isOpen: false }),
      })),
    updateModal: (name, data) =>
      set(state => ({
        modals: updateModalState(state.modals, name, data),
      })),
    closeAll: () =>
      set(state => ({
        modals: Object.keys(state.modals).reduce(
          (acc, modalName) => updateModalState(acc, modalName as ModalName, { isOpen: false }),
          state.modals,
        ),
      })),
  };
});
