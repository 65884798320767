import { getAllUrlParams } from '../../../utils/getAllUrlParams';
import { AnalyticsEvent } from '../AnalyticsEvents';
import type { AnalyticsHookProps, AnalyticsHookReturn } from '../AnalyticsProvider';

export const googleTagManagerAnalytics = (props: AnalyticsHookProps): AnalyticsHookReturn => {
  const { marketingTracking } = props;
  const urlParams = getAllUrlParams();

  const page = () => {
    window.dataLayer?.push({
      event: AnalyticsEvent.Page.PAGE_LOADED,
      page: location.pathname,
      title: document?.title,
    });
  };
  const event = (eventName: string, eventProperties: any) => {
    window.dataLayer?.push({
      event: eventName,
      ...marketingTracking,
      ...eventProperties,
      urlParams: urlParams,
    });
  };
  return { page, event };
};
