import merge from 'lodash.merge';
import type { ComponentType } from 'react';

import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';

import type { AddItemAndDetailsMutationVariables, AssetType, CollateralType } from '../../../__generated__/graphql/api';
import type { DeepPartial } from '../../../types/customTypes';

export interface QuickAppraisalStoreWithCollateralType extends AddItemAndDetailsMutationVariables {
  collateralType: CollateralType;
}

export type PartialQuickAppraisalStorePayload = DeepPartial<QuickAppraisalStoreWithCollateralType>;
export type QuickAppraisalStorePayload = PartialQuickAppraisalStorePayload | null;

export type AddItemAndDetailsMutationVariablesWithoutCreditAppID = Omit<
  AddItemAndDetailsMutationVariables,
  'creditApplicationId'
>;

export interface QuickAppraisalLocalStorage extends AddItemAndDetailsMutationVariablesWithoutCreditAppID {
  collateralType?: CollateralType;
}

export interface StateType {
  skipMutation?: boolean;
}

export interface QAStep {
  effect?: () => void;
  shouldSkip?: (state: QuickAppraisalStore) => boolean;
  component?: ComponentType<any>;
  props?: Record<string, any>;
}

interface HistoryStep {
  stepIndex: number;
  payloadSnapshot: QuickAppraisalStorePayload;
}

export interface QuickAppraisalState {
  payload: QuickAppraisalStorePayload;
  state: StateType;
  loading: boolean;
  itemType: AssetType | null;
  currentStepIndex: number;
  historySteps: HistoryStep[];
}

export interface QuickAppraisalActions {
  setState: (state: StateType) => void;
  setStep: (stepIndex: number) => void;
  setPayload: (payload: QuickAppraisalStorePayload) => void;
  clearPayload: () => void;
  setLoading: (loading: boolean) => void;
  setItemType: (itemType: AssetType | null) => void;
  addHistoryStep: (stepIndex: number, payloadSnapshot: QuickAppraisalStorePayload) => void;
  goToNextStep: (data: QuickAppraisalStorePayload, steps: QAStep[]) => QuickAppraisalStore;
  goBack: (steps: QAStep[]) => void;
  resetFlow: (itemType?: AssetType) => void;
  reset: () => void;
}

export type QuickAppraisalStore = QuickAppraisalState & QuickAppraisalActions;

export const findNextUnskippedStepIndex = (startIndex: number, steps: QAStep[], state: QuickAppraisalStore) => {
  let nextIndex = startIndex;
  while (nextIndex < steps.length) {
    const step = steps[nextIndex];
    if (step.shouldSkip && step.shouldSkip(state)) {
      nextIndex++;
    } else {
      break;
    }
  }
  return nextIndex;
};

const defaultState: QuickAppraisalState = {
  payload: null,
  state: {},
  loading: false,
  itemType: null,
  currentStepIndex: 0,
  historySteps: [{ stepIndex: 0, payloadSnapshot: null }],
};

export const createQuickAppraisalStore = (localStorageKey: string) => {
  return createStore<QuickAppraisalStore>()(
    persist(
      immer((set, get) => ({
        ...defaultState,
        setPayload: payload => set({ payload }),
        clearPayload: () => set({ payload: null }),
        setState: state => set({ state }),
        setLoading: loading => set({ loading }),
        setItemType: itemType => {
          get().reset();
          set({ itemType: itemType });
        },
        addHistoryStep: (stepIndex, payloadSnapshot) => {
          const state = get();
          const newHistoryStep: HistoryStep = {
            stepIndex,
            payloadSnapshot: structuredClone(payloadSnapshot),
          };
          const newHistorySteps = [...state.historySteps, newHistoryStep];

          set({ ...state, historySteps: newHistorySteps });
        },
        setStep: (stepIndex: number) => set(() => ({ currentStepIndex: stepIndex })),
        goToNextStep: (data, steps) => {
          const state = get();
          const nextStepIndex = state.currentStepIndex + 1;
          const updatedState = { ...state, payload: { ...merge(state.payload, data) } };
          const veryLastStepIndex = findNextUnskippedStepIndex(nextStepIndex, steps, updatedState);

          set(updatedState);

          const finalStepIndex =
            steps.slice(nextStepIndex).findIndex((step, index) => {
              const stepIndex = nextStepIndex + index;
              if (stepIndex === veryLastStepIndex) {
                return true;
              }
              return !(step.shouldSkip && step.shouldSkip(updatedState));
            }) + nextStepIndex;

          set({ currentStepIndex: finalStepIndex });
          get().addHistoryStep(finalStepIndex, updatedState.payload);
          return get();
        },
        goBack: () => {
          const state = get();
          const newHistorySteps = state.historySteps;
          newHistorySteps.pop();

          const previousStep = newHistorySteps[newHistorySteps.length - 1];
          const previousStepIndex = previousStep.stepIndex;
          const previousPayload = previousStep.payloadSnapshot;

          set({
            currentStepIndex: previousStepIndex,
            payload: structuredClone(previousPayload),
            historySteps: newHistorySteps,
          });
        },
        resetFlow: itemType => {
          get().reset();
          set({ itemType });
        },
        reset: () => set(() => defaultState),
      })),
      {
        name: localStorageKey,
      },
    ),
  );
};
