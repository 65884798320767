import type { User } from '@pesto/hubble-shared/__generated__/graphql/api';
import { CreditApplicationStatus } from '@pesto/hubble-shared/__generated__/graphql/api';

import { getCurrentProgramConfig } from '../../config/programHelper';

const flowHandler = (
  statusHandlers: Record<CreditApplicationStatus, (user?: User | null) => string>,
  creditAppStatus?: CreditApplicationStatus,
  currentUser?: User | null,
) => {
  const status = creditAppStatus || currentUser?.creditApplication?.status;
  const handler = statusHandlers[status || CreditApplicationStatus.InvalidState];
  return handler(currentUser);
};

export const getURLPathForStatus = (creditAppStatus?: CreditApplicationStatus) => {
  const { statusHandlers } = getCurrentProgramConfig();

  if (!statusHandlers) {
    console.log(`navigate to /, no status handlers found`);
    return '/';
  }

  // Log the status handler
  console.log(`navigate to ${flowHandler(statusHandlers, creditAppStatus)} -> ${creditAppStatus}`);

  return flowHandler(statusHandlers, creditAppStatus);
};
