import { AssetType, CreditApplicationStatus } from '@pesto/hubble-shared/__generated__/graphql/api';
import { SKIP_NAVIGATE } from '@pesto/hubble-shared/constants/shared-constants';
import isEmpty from 'lodash.isempty';
import { useCallback } from 'react';

import { useFetchItems } from '../../../components/features/ItemCart/hooks/useFetchItems';
import { useGoRoutes } from '../../../hooks/useGoRoutes';
import { useUser } from '../../../hooks/useUser';

export const useCreditApp = () => {
  const { user } = useUser();
  const {
    itemFlow: { gotoQuickAppraisal },
  } = useGoRoutes();
  const { items } = useFetchItems({
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const userStatus = user?.creditApplication?.status;

  const activeItem = items?.currentUser?.creditApplication?.items?.filter(item => item?.active);

  const noItems = !activeItem || activeItem?.length === 0;

  const activeItems = items?.currentUser?.creditApplication?.items?.filter(
    item => item?.active && !item?.qaRejectionReason,
  );

  const isCreditLimitTooLow = Boolean(items?.currentUser?.creditApplication?.creditTooLow);
  const hasWatchInReview = Boolean(activeItems?.find(item => item?.qaNeedsReview && item?.type === AssetType.Watch));
  const billingAddress = user?.billingAddress;
  const hasEnteredBillingAddress = !isEmpty(billingAddress);

  const isOtherItemInReview = Boolean(
    items?.currentUser?.creditApplication?.status === CreditApplicationStatus.ItemUnderReview,
  );

  const goToAddItem = useCallback(() => {
    gotoQuickAppraisal({ state: { SKIP_NAVIGATE } });
  }, [gotoQuickAppraisal]);

  return {
    userStatus,
    noItems,
    isCreditLimitTooLow,
    hasWatchInReview,
    hasEnteredBillingAddress,
    isOtherItemInReview,
    goToAddItem,
  };
};
