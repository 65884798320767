import { getUserConsent } from '@pesto/hubble-shared/utils/consentHelper';
import { hasUserCookie } from '@pesto/hubble-shared/utils/userCookie';

let segmentLoadPromise: Promise<unknown> | null = null;

const loadSegment = async (apiKey: string) => {
  const { functional } = getUserConsent();
  const hasUserCookieStored = hasUserCookie();

  if (!functional || !hasUserCookieStored) {
    return null;
  }

  // Return existing promise if it's already in progress
  if (segmentLoadPromise) {
    return segmentLoadPromise;
  }

  segmentLoadPromise = new Promise((resolve, reject) => {
    if (window.analytics) {
      window.analytics.load(apiKey);
      window.analytics.ready(() => {
        resolve('Segment script loaded successfully.');
        segmentLoadPromise = null; // Reset the promise after it has been resolved
      });
    } else {
      reject(new Error('window.analytics is not available.'));
      segmentLoadPromise = null; // Reset the promise in case of an error
    }
  });

  return segmentLoadPromise;
};

export const initSegment = (apiKey: string) => {
  const initialized = window?.analytics?.initialized;
  typeof window !== 'undefined' && !initialized && loadSegment(apiKey);
};
