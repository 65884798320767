import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

export const UsefulCopyPaste = () => {
  return (
    <div className="relative mt-6 px-4 sm:px-6">
      <div
        className="relative my-4 flex flex-col gap-4 rounded border border-green-500 bg-green-100 px-4 py-3"
        role="alert"
      >
        <div className="text-lg font-semibold text-green-800">Useful copy and paste</div>
        <Typography variant={'body'} className="inline">
          Test card number: <span className="font-bold">5489221100112235</span>
        </Typography>
      </div>
    </div>
  );
};
