import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { ReactAppEnv } from 'src/constants/global';

const env = ReactAppEnv;
const SITE = 'datadoghq.com';
const SERVICE = 'hubble-application';

datadogLogs.init({
  // version: import.meta.env.VITE_RELEASE || '',
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || '',
  site: SITE,
  service: SERVICE,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error', 'warn'],
  sessionSampleRate: 100,
  env,
});

datadogRum.init({
  // version: import.meta.env.VITE_RELEASE || '',
  applicationId: import.meta.env.VITE_DATADOG_CLIENT_APPLICATION_ID || '',
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || '',
  site: SITE,
  service: SERVICE,
  env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackingConsent: 'not-granted',
});

datadogRum.startSessionReplayRecording();

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
