import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import SvgCloseWhite from '@pesto/hubble-shared/icons/components/CloseWhite';
import SvgWhiteInfo from '@pesto/hubble-shared/icons/components/WhiteInfo';
import type { FC, ReactNode } from 'react';
import { Fragment, useRef } from 'react';

import { Z_INDEX } from '../../../constants/numeric-constants';
import { cn } from '../../../utils/cn';

interface ModalProps {
  isOpen: boolean;
  theme?: 'dark' | 'light';
  onClose: () => void;
  children: ReactNode;
  className?: string;
  blocking?: boolean;
}

export const Modal2: FC<ModalProps> = props => {
  const { isOpen = false, onClose, children, theme = 'light', className, blocking = false } = props;
  const cancelButtonRef = useRef(null);
  const rootStyle = cn(
    'relative transform overflow-hidden rounded-lg text-center shadow-xl transition-all sm:w-full sm:max-w-sm',
    className,
    {
      'bg-darkShade text-white': theme === 'dark',
      'bg-white text-darkShade': theme === 'light',
    },
  );

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: Z_INDEX.MODAL }}
        initialFocus={cancelButtonRef}
        onClose={blocking ? () => {} : onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-darkShade fixed inset-0 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className={rootStyle}>
                <div className="px-4 pb-4 pt-5 sm:my-8 sm:p-6">
                  {theme === 'dark' && (
                    <div className="mb-4 flex justify-center">
                      <SvgWhiteInfo className="h-auto w-9" />
                    </div>
                  )}
                  {children}
                </div>
                {theme === 'dark' && (
                  <button onClick={onClose} className="hover:bg-basic flex w-full justify-center bg-gray-950 p-4">
                    <SvgCloseWhite />
                  </button>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
