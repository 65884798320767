import {
  DEBUGGER_PROGRAM_LS_KEY,
  IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY,
} from '@pesto/hubble-shared/constants/local-storage-keys';
import { useState } from 'react';

import { isFeatureFlagEnabled, setFeatureFlag } from 'src/utils/adminDebuggerHelper';

import type { Program } from '../../../../config/program';
import { getProgram } from '../../../../config/programHelper';

export const useDebugger = () => {
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(getProgram());

  const updateSelectedProgram = (program: Program) => {
    setSelectedProgram(program);
    localStorage.setItem(DEBUGGER_PROGRAM_LS_KEY, program);
    window.location.reload();
  };

  const resetProgram = () => {
    setSelectedProgram(null);
    localStorage.removeItem(DEBUGGER_PROGRAM_LS_KEY);
    window.location.reload();
  };

  const getIsSegmentAndPestoTrackingIgnoredForDevMode = (): boolean => {
    return isFeatureFlagEnabled(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY);
  };

  const handleSegmentAndPestoTrackingIgnoreStatusForDevMode = (value: boolean) => {
    setFeatureFlag(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY, value);
  };

  return {
    selectedProgram,
    updateSelectedProgram,
    resetProgram,
    getIsSegmentAndPestoTrackingIgnoredForDevMode,
    handleSegmentAndPestoTrackingIgnoreStatusForDevMode,
  };
};
