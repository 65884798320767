import { CurrentUserDocument, type CurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { apolloClient } from '@pesto/hubble-shared/api/Apollo';
import { getUserConsent } from '@pesto/hubble-shared/utils/consentHelper';
import { getCookies, parseCookies, setCookies } from '@pesto/hubble-shared/utils/cookies';

import { USER_DATA_COOKIE_KEY } from '../constants/local-storage-keys';

interface UserCookie {
  userId: string;
}

export const setUserCookie = (userData: UserCookie) => {
  const { performance } = getUserConsent();
  if (!performance) return null;
  return setCookies(USER_DATA_COOKIE_KEY, JSON.stringify(userData), 365);
};

export const setUserCookieFromApolloCache = () => {
  const user = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument })?.currentUser;
  if (!user || !user?.id) return null;

  return setUserCookie({
    userId: user.id,
  });
};

export const getUserCookie = (): UserCookie | null => {
  const userCookie = getCookies(USER_DATA_COOKIE_KEY);
  return userCookie ? parseCookies(userCookie) : null;
};

export const hasUserCookie = (): boolean => {
  return !!getUserCookie();
};
