import { getUserConsent } from '@pesto/hubble-shared/utils/consentHelper';
import { LocalStorage } from '@pesto/hubble-shared/utils/core/BrowserStorage';
import axios from 'axios';

import {
  PESTO_ANONYMOUS_EVENT_SENT_LS_KEY,
  PESTO_IDENTIFY_EVENT_SENT_LS_KEY,
  SEGMENT_ANONYMOUS_ID_LS_KEY,
} from '../../../constants/local-storage-keys';
import { AnalyticsEvent } from '../../../providers/analytics/AnalyticsEvents';
import { getAllUrlParams, urlObject } from '../../../utils/getAllUrlParams';
import type { AnalyticsHookProps, PestoAnalyticsHookReturn } from '../AnalyticsProvider';

export const pestoAnalytics = (props: AnalyticsHookProps): PestoAnalyticsHookReturn => {
  const urlParams = getAllUrlParams();

  const {
    currentUser,
    marketingTracking,
    environmentVariables: { pestoAnalyticsUrl },
  } = props;

  const { functional } = getUserConsent();
  const sendToPestoAnalytics = (eventName: string, eventProperties: any) => {
    if (!functional) return null;
    axios
      .post(pestoAnalyticsUrl, {
        eventName,
        eventProperties,
      })
      .catch(error => {
        console.error('Error sending event to Pesto Analytics', error, eventName, eventProperties);
      });
  };

  const eventPropertiesWithMarketingParams = (eventProperties: any, userInfo?: any) => ({
    ...globalPayload,
    properties: {
      eventProperties,
      ...marketingTracking,
      urlParams: urlParams,
      urlData: urlData,
      segmentAnonymousId: segmentAnonymousId,
      ...userInfo,
    },
  });

  const pestoIdent = () => {
    if (currentUser && currentUser?.id) {
      sendToPestoAnalytics('identify', {
        name: currentUser?.displayName ?? '',
        email: currentUser?.email ?? '',
      });
    }
  };

  const segmentAnonymousId = LocalStorage.getItem(SEGMENT_ANONYMOUS_ID_LS_KEY);
  const urlData = {
    url: urlObject(),
    title: document.title,
    referrer: document.referrer,
    searchParams: urlParams,
  };
  const globalPayload = {
    segmentAnonymousId: segmentAnonymousId,
    sendAt: new Date().toISOString(),
    timeStamp: new Date().getTime(),
  };
  const event = (eventName: string, eventProperties: any) => {
    const eventPayload = eventPropertiesWithMarketingParams(eventProperties);
    sendToPestoAnalytics(eventName, eventPayload);
  };

  const page = (eventProperties?: any) => {
    const eventPayload = eventPropertiesWithMarketingParams(eventProperties);
    pestoIdent();
    sendToPestoAnalytics(AnalyticsEvent.Page.PAGE_LOADED, eventPayload);
  };

  const identifyPage = (eventProperties?: any) => {
    const anonymousEventSent = LocalStorage.getItem(PESTO_ANONYMOUS_EVENT_SENT_LS_KEY) !== null;
    const identifyEventSent = LocalStorage.getItem(PESTO_IDENTIFY_EVENT_SENT_LS_KEY) !== null;
    const canSendIdentifyEvent = !identifyEventSent && currentUser && currentUser?.id;
    const canSendAnonymousEvent = !anonymousEventSent && !currentUser;
    const timestampUtc = new Date().toISOString();

    const userInfo = {
      name: currentUser?.displayName ?? '',
      email: currentUser?.email ?? '',
      userId: currentUser?.id ?? '',
    };
    const eventPayload = eventPropertiesWithMarketingParams(eventProperties, canSendIdentifyEvent && { ...userInfo });

    if (canSendIdentifyEvent) {
      LocalStorage.setItem(PESTO_IDENTIFY_EVENT_SENT_LS_KEY, timestampUtc);
      return sendToPestoAnalytics(AnalyticsEvent.Page.PAGE_IDENTIFIED, eventPayload);
    }

    if (canSendAnonymousEvent) {
      LocalStorage.setItem(PESTO_ANONYMOUS_EVENT_SENT_LS_KEY, timestampUtc);
      return sendToPestoAnalytics(AnalyticsEvent.Page.PAGE_ANONYMOUS, eventPayload);
    }
  };

  return { page, event, identifyPage };
};
