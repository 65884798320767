import { Program } from './program';
import type { ThemeConfig } from './program.types';

const pestoTheme: ThemeConfig = {
  font: {
    fontSans: 'Factor-A',
    fontDisplay: 'Factor-A',
    fontArch: 'Archivo',
  },
  colors: {
    foreground: '0 0% 0%',
    background: '0 0% 100%',
    accent: '0 0% 0%',
    secondary: '0 0% 0%',
    success: '142 76% 36%',
    warning: '36 100% 50%',
    danger: '360 76% 50%',
    disabled: '210 10% 45%',
    buttonPrimary: 'var(--accent)',
    buttonPrimaryText: '255 100% 100%',
    buttonSecondary: 'var(--secondary)',
    buttonSecondaryText: 'var(--secondary)',
  },
};

export const programThemes: Record<Program, Partial<ThemeConfig>> = {
  [Program.PESTO_ITEM]: pestoTheme,
  [Program.PESTO_CASH]: pestoTheme,
  [Program.HOMELIGHT]: {
    font: {
      fontSans: 'Factor-A',
      fontDisplay: 'Factor-A',
      fontArch: 'Archivo',
    },
    colors: {
      foreground: '220 74% 11%',
      background: '0 100% 100%',
      accent: '220 74% 11%',
      secondary: '0 0% 0%',
      success: '142 76% 36%',
      warning: '36 100% 50%',
      danger: '360 76% 50%',
      disabled: '210 10% 45%',
      buttonPrimary: 'var(--accent)',
      buttonPrimaryText: '255 100% 100%',
      buttonSecondary: 'var(--secondary)',
      buttonSecondaryText: 'var(--secondary)',
      headerBackground: 'var(--accent)',
    },
  },
  [Program.TROVY]: {
    font: {
      fontSans: 'Factor-A',
      fontDisplay: 'Factor-A',
      fontArch: 'Archivo',
    },
    colors: {
      foreground: '203 98% 33%',
      background: '0 100% 100%',
      accent: '226 99% 64%',
      secondary: '0 0% 0%',
      success: '142 76% 36%',
      warning: '36 100% 50%',
      danger: '360 76% 50%',
      disabled: '210 10% 45%',
      buttonPrimary: 'var(--accent)',
      buttonPrimaryText: '255 100% 100%',
      buttonSecondary: 'var(--secondary)',
      buttonSecondaryText: 'var(--secondary)',
    },
  },
  [Program.SAFEGUARD]: {
    font: {
      fontSans: 'Factor-A',
      fontDisplay: 'Factor-A',
      fontArch: 'Archivo',
    },
    colors: {
      foreground: '216 100% 60%',
      background: '0 100% 100%',
      accent: '216 100% 60%',
      secondary: '0 0% 0%',
      success: '142 76% 36%',
      warning: '36 100% 50%',
      danger: '360 76% 50%',
      disabled: '210 10% 45%',
      buttonPrimary: 'var(--accent)',
      buttonPrimaryText: '255 100% 100%',
      buttonSecondary: 'var(--secondary)',
      buttonSecondaryText: 'var(--secondary)',
    },
  },
  [Program.HONOR]: {
    font: {
      fontSans: 'Factor-A',
      fontDisplay: 'Factor-A',
      fontArch: 'Archivo',
    },
    colors: {
      foreground: '214 42% 20%',
      background: '0 100% 100%',
      accent: '214 42% 20%',
      secondary: '0 0% 0%',
      success: '142 76% 36%',
      warning: '36 100% 50%',
      danger: '360 76% 50%',
      disabled: '210 10% 45%',
      buttonPrimary: 'var(--accent)',
      buttonPrimaryText: '255 100% 100%',
      buttonSecondary: 'var(--secondary)',
      buttonSecondaryText: 'var(--secondary)',
    },
  },
};
