import SvgSettingsIcon from '@pesto/hubble-shared/icons/components/SettingsIcon';
import clsx from 'clsx';
import { type ReactNode, useEffect, useState } from 'react';

import { DebuggerDrawer } from './DebuggerDrawer';
import { isDebuggerEnabled } from './adminDeguggerHeper';

interface AdminDebuggerProps {
  className?: string;
  isOpen?: boolean;
  content?: ReactNode;
}

export const AdminDebugger = (props: AdminDebuggerProps) => {
  const { className, isOpen, content } = props;
  const [showDebugger, setShowDebugger] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(isOpen ?? false);
  useEffect(() => {
    const isEmbeddedInIframe = window.self !== window.top;

    if (isEmbeddedInIframe) {
      setShowDebugger(false);
    } else {
      setShowDebugger(true);
    }
  }, []);

  if (!isDebuggerEnabled || !showDebugger) {
    return null;
  }

  return (
    <div className={clsx('z-[999999999]', className)}>
      <DebuggerDrawer className={'z-[999999999]'} open={open} onClose={setOpen}>
        {content}
      </DebuggerDrawer>

      <div className={'fixed right-0 top-1/2 -translate-y-1/2'}>
        <button
          className={clsx(
            'bg-basic hover:bg-basicSoft flex h-10 w-10 flex-col items-center justify-center rounded-l-lg',
          )}
          onClick={() => setOpen(!open)}
        >
          <SvgSettingsIcon className={clsx('text-xl text-white')} />
        </button>
      </div>
    </div>
  );
};
