import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#card_svg__a)">
      <path d="M38.818 10.278 29.521.982a3.36 3.36 0 0 0-4.747 0l-5.228 5.227a.781.781 0 0 0 1.105 1.105l5.228-5.228c.7-.7 1.838-.7 2.538 0l9.297 9.297c.7.7.7 1.838 0 2.538L22.397 29.238c-.34.34-.79.526-1.27.526-.479 0-.93-.187-1.269-.526l-.64-.639 3.57-3.57a3.116 3.116 0 0 0 0-4.401 3.116 3.116 0 0 0-4.402 0l-3.164 3.164-.513.008a7 7 0 0 0-.279.01l-3.869-3.868c-.7-.7-.7-1.839 0-2.539l5.12-5.118a.781.781 0 0 0-1.105-1.105l-5.12 5.119a3.36 3.36 0 0 0 0 4.747l3.128 3.128c-.95.33-1.821.871-2.551 1.601a6.7 6.7 0 0 0-1.976 4.701l-.006.555L.43 38.654a.781.781 0 0 0 1.104 1.104l7.848-7.847a.78.78 0 0 0 .229-.545l.009-.873a5.16 5.16 0 0 1 1.518-3.613 5.16 5.16 0 0 1 3.594-1.518l.83-.012a.78.78 0 0 0 .541-.229l3.389-3.389a1.55 1.55 0 0 1 2.193 0 1.55 1.55 0 0 1 0 2.193L16.6 29.009a.78.78 0 1 0 1.105 1.104l.41-.41.639.64a3.35 3.35 0 0 0 2.374.982c.86 0 1.719-.328 2.373-.982l3.629-3.628-2.021 5.472a5.4 5.4 0 0 1-3.585 3.314l-6.247 1.766a.8.8 0 0 0-.34.2l-1.2 1.2a.781.781 0 0 0 1.104 1.104l1.057-1.056 6.051-1.71a6.97 6.97 0 0 0 4.625-4.277l3.196-8.653 9.049-9.05a3.36 3.36 0 0 0 0-4.747" />
      <path d="M25.521 21.173a.78.78 0 0 0 1.105 0l8.106-8.106a.781.781 0 0 0-1.105-1.105l-8.106 8.107a.78.78 0 0 0 0 1.104M16.978 14.823l-2.61 2.61a.781.781 0 0 0 1.104 1.105l2.61-2.61a.781.781 0 0 0-1.104-1.105M17.613 10.028a.785.785 0 0 0 .781-.781.79.79 0 0 0-.229-.552.79.79 0 0 0-.552-.23.79.79 0 0 0-.552.23.79.79 0 0 0-.229.552.785.785 0 0 0 .781.78M27.285 11.2a2.777 2.777 0 0 1-2.774-2.775 2.777 2.777 0 0 1 2.774-2.773 2.777 2.777 0 0 1 2.774 2.773 2.777 2.777 0 0 1-2.774 2.774m0-3.986c-.668 0-1.212.543-1.212 1.211a1.213 1.213 0 0 0 2.423 0c0-.668-.543-1.211-1.21-1.211" />
    </g>
    <defs>
      <clipPath id="card_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCard;
