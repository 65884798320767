import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

import {
  onboardingFundFullDeposit,
  onboardingPestoOverTime,
} from '../../../../flows/cash/pages/PaymentWrapper/FundOverTime/components/InfoText';
import ItemList from '../../../../flows/cash/pages/PaymentWrapper/FundOverTime/components/ItemList';

interface OnboardingModalProps {
  onClose: () => void;
  isOpen: boolean;
  onContinue?: () => void;
}

export const OnboardingModal = (props: OnboardingModalProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <div className={'grid max-h-[90vh] overflow-auto'}>
        <div className="col-start-1 row-start-1 flex flex-col items-center justify-center gap-4">
          <Typography variant="headerMedium">Let's explore your payment options</Typography>

          <div>
            <Typography variant="headerSmall" className="mb-2">
              Fund full deposit
            </Typography>

            <div className="bg-bisque flex w-full max-w-[440px] flex-col gap-4 rounded bg-opacity-40 p-4 text-left text-amber-800">
              <ItemList items={onboardingFundFullDeposit} />
            </div>
          </div>

          <div>
            <Typography variant="headerSmall" className="mb-2">
              Fund over time
            </Typography>

            <div className="bg-bisque flex w-full max-w-[440px] flex-col gap-4 rounded bg-opacity-40 p-4 text-left text-amber-800">
              <ItemList items={onboardingPestoOverTime} />
            </div>
          </div>
          <div className="mb-28" />
        </div>

        <div className={'relative col-start-1 row-start-1 flex items-end'}>
          <div className="sticky bottom-0 flex w-full flex-row justify-between">
            <Button buttonType={'primary'} onClick={onClose} text={'Continue'} />
          </div>
        </div>
      </div>
    </Modal2>
  );
};
