import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { Suspense } from 'react';

import { GlobalModals } from 'src/components/features/GlobalModals/GlobalModals';

import { getCurrentProgramConfig } from '../config/programHelper';

const RouterProvider = () => {
  const { RouteComponent } = getCurrentProgramConfig();

  return (
    <Suspense fallback={<LoaderSpinnerFull />}>
      <RouteComponent />
      <GlobalModals />
    </Suspense>
  );
};

export default RouterProvider;
