import type { CurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import type { NonNullUtmParams } from '@pesto/hubble-shared/utils/URL/utmParamsHelper';
import { getNonNullUtmParamsForTrack } from '@pesto/hubble-shared/utils/URL/utmParamsHelper';
import type { ReactElement } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY } from '../../constants/local-storage-keys';
import { LocalStorage } from '../../utils/core/BrowserStorage';
import { useEnvironmentVariables } from '../HubbleSharedProvider';

import { googleTagManagerAnalytics } from './utils/googleTagManagerAnalytics';
import { pestoAnalytics } from './utils/pestoAnalytics';
import { segmentAnalytics } from './utils/segmentAnalytics';

/**
 * If there's a need in the future to add another capture engine, we can
 * add it to the interface and make it available through the hook
 * example:
 * {
 *   dataDog: {...},
 *   sentry: {...}
 * }
 */

export interface AnalyticsHookReturn {
  event: (eventName: string, eventProperties: Record<string, unknown>, experiment?: Record<string, unknown>) => void;
  page: (pageName?: string, eventProperties?: Record<string, unknown>) => void;
}
export interface PestoAnalyticsHookReturn extends AnalyticsHookReturn {
  identifyPage: (eventProperties?: Record<string, unknown>) => void;
}
interface AnalyticsState {
  segment: AnalyticsHookReturn;
  pestoAnalytics: AnalyticsHookReturn;
  googleTagManager: AnalyticsHookReturn;
  analytics: AnalyticsHookReturn;
}

export interface AnalyticsHookProps {
  currentUser: CurrentUserQuery['currentUser'];
  marketingTracking: NonNullUtmParams;
  environmentVariables: {
    pestoAnalyticsUrl: string;
  };
}

export interface AnalyticsProviderProps {
  children: ReactElement | ReactElement[];
  currentUser?: CurrentUserQuery['currentUser'];
  isProd: boolean;
}

const AnalyticsContext = createContext<AnalyticsState>({} as AnalyticsState);
export function AnalyticsProvider(props: AnalyticsProviderProps) {
  const { children, currentUser, isProd } = props;
  const isSegmentTrackingIgnoredForDevMode =
    LocalStorage.getItem(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY) === 'true';

  const { pestoAnalyticsUrl } = useEnvironmentVariables();

  const marketingTracking = getNonNullUtmParamsForTrack();

  const analyticsProps: AnalyticsHookProps = useMemo(() => {
    return {
      currentUser,
      marketingTracking,
      environmentVariables: {
        pestoAnalyticsUrl,
      },
    };
  }, [currentUser, marketingTracking, pestoAnalyticsUrl]);

  const { event: segmentEvent, page: segmentPage } = segmentAnalytics(analyticsProps);
  const { event: googleTagManagerEvent, page: googleTagManagerPage } = googleTagManagerAnalytics(analyticsProps);
  const { event: pestoEvent, page: pestoPage, identifyPage: pestoIdentifyPage } = pestoAnalytics(analyticsProps);

  const shouldDoEventInDevMode = !isSegmentTrackingIgnoredForDevMode && !isProd;

  const analyticsEvent = useCallback(
    (eventName: string, eventProperties: Record<string, unknown>) => {
      shouldDoEventInDevMode && segmentEvent(eventName, eventProperties);
      shouldDoEventInDevMode && pestoEvent(eventName, eventProperties);
      googleTagManagerEvent(eventName, eventProperties);
    },
    [shouldDoEventInDevMode, segmentEvent, googleTagManagerEvent, pestoEvent],
  );

  const analyticsPage = useCallback(
    (pageName?: string, eventProperties?: Record<string, unknown>) => {
      shouldDoEventInDevMode && segmentPage(pageName, eventProperties);
      shouldDoEventInDevMode && pestoIdentifyPage(eventProperties);
      googleTagManagerPage();
    },
    [shouldDoEventInDevMode, segmentPage, googleTagManagerPage, pestoIdentifyPage],
  );

  const analyticsState: AnalyticsState = useMemo(
    () => ({
      segment: {
        event: segmentEvent,
        page: segmentPage,
      },
      googleTagManager: {
        event: googleTagManagerEvent,
        page: googleTagManagerPage,
      },
      pestoAnalytics: {
        event: pestoEvent,
        page: pestoPage,
      },
      analytics: {
        event: analyticsEvent,
        page: analyticsPage,
      },
    }),
    [
      analyticsEvent,
      analyticsPage,
      googleTagManagerEvent,
      googleTagManagerPage,
      pestoEvent,
      pestoPage,
      segmentEvent,
      segmentPage,
    ],
  );

  return <AnalyticsContext.Provider value={analyticsState}>{children}</AnalyticsContext.Provider>;
}

export function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an analytics provider');
  }
  return context;
}
