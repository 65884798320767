import { DEBUGGER_PROGRAM_LS_KEY } from '@pesto/hubble-shared/constants/local-storage-keys';

import { Program } from './program';
import type { CashFlowConfig, HelocFlowConfig, ItemFlowConfig, ProgramConfig } from './program.types';
import { FlowType, programConfig } from './programConfig';

export const getProgram = (): Program => {
  const debuggerProgram = localStorage.getItem(DEBUGGER_PROGRAM_LS_KEY);

  if (debuggerProgram) {
    return debuggerProgram as Program;
  }

  const program = import.meta.env.VITE_PROGRAM;
  if (!program) {
    return Program.HOMELIGHT;
  }
  return program satisfies Program;
};

export const getCurrentProgramConfig = (): ProgramConfig => {
  const program = getProgram();
  return programConfig[program];
};

export const getFlowConfig = <T extends ProgramConfig>(config: ProgramConfig): T => {
  return config as T;
};

export const isHelocFlow = (config: ProgramConfig): config is HelocFlowConfig => {
  return config.flowType === FlowType.HELOC;
};

export const isCashFlow = (config: ProgramConfig): config is CashFlowConfig => {
  return config.flowType === FlowType.CASH;
};

export const isItemFlow = (config: ProgramConfig): config is ItemFlowConfig => {
  return config.flowType === FlowType.ITEM;
};
