import { getAllUrlParams } from '../../../utils/getAllUrlParams';
import type { AnalyticsHookProps, AnalyticsHookReturn } from '../AnalyticsProvider';

export const segmentAnalytics = (props: AnalyticsHookProps): AnalyticsHookReturn => {
  const urlParams = getAllUrlParams();
  const { currentUser, marketingTracking } = props;

  const segmentIdent = () => {
    if (currentUser && currentUser?.id) {
      window.analytics?.identify(currentUser.id, {
        name: currentUser?.displayName ?? '',
        email: currentUser?.email ?? '',
      });
    }
  };

  const event = (eventName: string, eventProperties: any) => {
    segmentIdent();
    const eventPropertiesWithMarketingParams = {
      eventProperties,
      urlParams: urlParams,
      ...marketingTracking,
    };
    window.analytics?.track(eventName, eventPropertiesWithMarketingParams);
  };

  const page = (pageName?: string, eventProperties?: any) => {
    const eventPropertiesWithMarketingParams = {
      eventProperties,
      ...marketingTracking,
      urlParams: urlParams,
    };
    segmentIdent();
    window.analytics?.page(pageName, eventPropertiesWithMarketingParams);
  };

  return { page, event };
};
