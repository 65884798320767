import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { clsx } from 'clsx';

import type { TextItem } from './InfoText';

interface ItemListTypeProps {
  className?: string;
  size?: 'small' | 'medium';
  items: TextItem[];
}

const ItemList = (props: ItemListTypeProps) => {
  const { items, className, size = 'medium' } = props;
  const style = clsx('flex flex-row items-center gap-1', className);

  return (
    <>
      {items.map(item => {
        return (
          <div key={item.title.slice(0, 3)} className={style}>
            <item.icon className="h-[40px] w-[40px]" />
            <div className="flex w-full flex-col gap-2 pl-4">
              <Typography variant={size === 'small' ? 'body' : 'headerSmall'} className={'!font-bold'}>
                {item.title}
              </Typography>
              <Typography variant={size === 'small' ? 'bodySmall' : 'body'}>{item.description}</Typography>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ItemList;
