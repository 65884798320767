import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { getCurrentProgramConfig } from '../../config/programHelper';

export const Favicon: FC = () => {
  const { favicon } = getCurrentProgramConfig();

  return (
    <Helmet>
      <link rel="icon" href={favicon} sizes="any" />
    </Helmet>
  );
};
