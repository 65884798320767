import { Helmet } from 'react-helmet-async';

import { getCurrentProgramConfig } from '../../config/programHelper';

export const MetaTags = () => {
  const {
    meta: { title, description },
  } = getCurrentProgramConfig();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
    </Helmet>
  );
};
