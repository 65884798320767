import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

interface SSNModalProps {
  modalError: boolean;
  setModalError: (value: boolean) => void;
  modalButtonText: string;
  handleModalButtonClick: () => void;
}

export const SsnDuplicateModal = ({
  modalError,
  setModalError,
  modalButtonText,
  handleModalButtonClick,
}: SSNModalProps) => {
  return (
    <Modal2 isOpen={modalError} onClose={() => setModalError(false)}>
      <div className="flex flex-col gap-4">
        <Typography variant="headerLarge">SSN or DOB mismatch</Typography>
        <Typography variant="bodyLarge">Please check your information and try again.</Typography>
        <Button buttonType="primary" text={modalButtonText} onClick={handleModalButtonClick} />
      </div>
    </Modal2>
  );
};
