import { LocalStorage } from '@pesto/hubble-shared/utils/core/BrowserStorage';

import {
  CONSENT_SETTING_COOKIE_KEY,
  FBCLID_LS_KEY,
  FLEX_REF_ID_LS_KEY,
  GCLID_LS_KEY,
  LG_LEAD_ID_LS_KEY,
  LG_SUB_ID_LS_KEY,
  MSCLKID_LS_KEY,
  NERD_WALLET_REF_LS_KEY,
  PESTOANONID_LS_KEY,
  REF_LS_KEY,
  REFERRAL_CODE_LS_KEY,
  SEGMENT_ANONYMOUS_ID_LS_KEY,
  TM_SUB_ID_1_KEY,
  TM_SUB_ID_2_KEY,
  TM_SUB_ID_3_KEY,
  UTM_CAMPAIGN_LS_KEY,
  UTM_CONTENT_LS_KEY,
  UTM_MEDIUM_LS_KEY,
  UTM_SOURCE_LS_KEY,
  UTM_STRING_LS_KEY,
} from '../../constants/local-storage-keys';
import { getUserConsent } from '../consentHelper';
import { getCookies } from '../cookies';

import { adminDebuggerUrlHelper } from './adminDebuggerUrlHelper';

export const REFERRAL_CODE_URL_PARAM = 'referralCode';

export const AD_PARAM_LS_FILTER_KEY = '-adParam';
export interface UtmParams {
  [key: string]: string | null;
}

export interface AdParams {
  [key: string]: string;
}

export type NonNullUtmParams = {
  [K in keyof UtmParams]: NonNullable<UtmParams[K]>;
};
export const parseURL = (url: string) => {
  return new URL(url);
};

export const getUtm = (): UtmParams => {
  // get all search params
  const urlParams = parseURL(window.location.href)?.searchParams;

  // convert to js object
  const searchParamsObject = urlParams && Object.fromEntries(urlParams.entries());
  if (!searchParamsObject) return {};
  // add -adParam to every key so we can then filter them out
  const searchParamsObjectNamed = Object.keys(searchParamsObject).reduce((acc: AdParams, key) => {
    acc[`${key}${AD_PARAM_LS_FILTER_KEY}`] = searchParamsObject[key];
    return acc;
  }, {});

  // whole query string
  let utmString: string | null = JSON.stringify(Object.fromEntries(urlParams.entries()));
  const utmSource = urlParams.get('utm_source') || null;
  let referralCode = urlParams.get(REFERRAL_CODE_URL_PARAM);

  if (utmSource && utmString) {
    LocalStorage.setItem(UTM_STRING_LS_KEY, utmString);
  }
  if (referralCode) {
    LocalStorage.setItem(REFERRAL_CODE_LS_KEY, referralCode);
  }

  // for every key in searchParamsObjectNamed, set the key and value in localStorage
  if (searchParamsObjectNamed) {
    Object.keys(searchParamsObjectNamed).forEach(key => {
      LocalStorage.setItem(key, searchParamsObjectNamed[key]);
    });
  }

  // get all search params with -adParam from local storage in the key and remove -adParam from the key
  const localStorageKeys = Object.keys(localStorage);
  const adParams = localStorageKeys.reduce((acc: AdParams, key) => {
    if (key.includes(AD_PARAM_LS_FILTER_KEY)) {
      acc[key.replace(AD_PARAM_LS_FILTER_KEY, '')] = LocalStorage.getItem(key) || '';
    }
    return acc;
  }, {});

  utmString = LocalStorage.getItem(UTM_STRING_LS_KEY);
  referralCode = LocalStorage.getItem(REFERRAL_CODE_LS_KEY);
  // get segment anonymous id from local storage that segment sets
  const segmentAnonymousId = LocalStorage.getItem(SEGMENT_ANONYMOUS_ID_LS_KEY);

  // get old utm params from local storage that were set in the past
  // for backwards compatibility
  const oldUtmParams = {
    utmSource: LocalStorage.getItem(UTM_SOURCE_LS_KEY),
    utmMedium: LocalStorage.getItem(UTM_MEDIUM_LS_KEY),
    utmCampaign: LocalStorage.getItem(UTM_CAMPAIGN_LS_KEY),
    utmContent: LocalStorage.getItem(UTM_CONTENT_LS_KEY),
    gclid: LocalStorage.getItem(GCLID_LS_KEY),
    fbclid: LocalStorage.getItem(FBCLID_LS_KEY),
    msclkid: LocalStorage.getItem(MSCLKID_LS_KEY),
    ref: LocalStorage.getItem(REF_LS_KEY),
    pestoAnonId: LocalStorage.getItem(PESTOANONID_LS_KEY),
    referralCode: LocalStorage.getItem(REFERRAL_CODE_LS_KEY),
    nerdwalletRef: LocalStorage.getItem(NERD_WALLET_REF_LS_KEY),
    lgLeadId: LocalStorage.getItem(LG_LEAD_ID_LS_KEY),
    lgSubId: LocalStorage.getItem(LG_SUB_ID_LS_KEY),
    tmSubid1: LocalStorage.getItem(TM_SUB_ID_1_KEY),
    tmSubid2: LocalStorage.getItem(TM_SUB_ID_2_KEY),
    tmSubid3: LocalStorage.getItem(TM_SUB_ID_3_KEY),
    flexRefId: LocalStorage.getItem(FLEX_REF_ID_LS_KEY),
  };

  adminDebuggerUrlHelper();

  return {
    // here the order is important, because we want to overwrite old utm params with new ones if they exist
    ...oldUtmParams,
    ...adParams,
    referralCode,
    utmString,
    segmentAnonymousId,
  };
};

const getUtmWithConsent = (): UtmParams => {
  const { performance } = getUserConsent();
  if (getCookies(CONSENT_SETTING_COOKIE_KEY) !== null) {
    return performance ? getUtm() : {};
  }
  return getUtm();
};

/**
 * Get utm params from the url and local storage
 * @returns utm params
 * @example
 * const utmParams = getUtmParams();
 * console.log(utmParams);
 * // {utmSource: 'google', utmMedium: 'cpc', utmCampaign: 'summer', utmContent: 'ad1', referralCode: null, utmString: null, segmentAnonymousId: null}
 * some of the values can be null
 **/
export const getUtmParams = (): UtmParams =>
  Object.fromEntries(Object.entries(getUtmWithConsent()).filter(([value]) => !!value));

/**
 * Get non-null utm params from the url or local storage that are not null and can be sent to be tracked
 * @returns non null utm params
 * @example
 * const nonNullUtmParams = getNonNullUtmParamsForTrack();
 * console.log(nonNullUtmParams);
 * // {utmSource: 'google', utmMedium: 'cpc', utmCampaign: 'summer', utmContent: 'ad1'}
 * none of the values are null
 **/
export const getNonNullUtmParamsForTrack = (): NonNullUtmParams => {
  const nonNullUtmParams = Object.entries(getUtmParams()).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc.push([key as keyof UtmParams, value]);
      }
      return acc;
    },
    [] as [keyof UtmParams, NonNullable<UtmParams[keyof UtmParams]>][],
  );
  return Object.fromEntries(nonNullUtmParams) as NonNullUtmParams;
};

export const removeUtmParams = () => {
  const localStorageKeys = Object.keys(localStorage);
  const utmKeys = localStorageKeys.filter(key => key.includes(AD_PARAM_LS_FILTER_KEY));

  utmKeys.forEach(key => {
    LocalStorage.removeItem(key);
  });
};
